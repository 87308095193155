<template>
  <div class="pt-10" v-if="loaded">
    <v-dialog max-width="660" v-model="speedUpDialog">
      <div class="summarySpeedUpDialog">
        <img
          @click="speedUpDialog = false"
          src="@/assets/ic_chevron_top_black.png"
        />
        <div class="summarySpeedUpDialog__title">Diritto di ripensamento</div>
        <div class="summarySpeedUpDialog__subtitle">
          Selezionando questa opzione richiedi di avviare anticipatamente la
          procedura per l’attivazione della tua fornitura prima del termine per
          l’esercizio del diritto di ripensamento, secondo quanto previsto
          dall’art. 5 delle Condizioni Generali di Contratto. Ricordati che
          potrai comunque esercitare il tuo diritto di ripensamento entro i
          termini previsti per legge (14 giorni dalla conclusione del contratto)
          ma, qualora Sorgenia avesse già avviato la fornitura e/o sostenuto dei
          costi per il suo avvio, sarai tenuto a corrisponderli ai sensi del
          Contratto.
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="activationDialog" max-width="775">
      <div class="dialog-modal">
        <h2 class="dialog-modal__title" v-if="!isAnticipata">
          La tua richiesta di anticipare l’attivazione è stata ricevuta, grazie!
        </h2>
        <h2 class="dialog-modal__title" v-if="isAnticipata">
          La tua richiesta di anticipare l’attivazione è già in lavorazione.
        </h2>
        <h4 class="dialog-modal__text pt-6">
          A breve aggiorneremo la pagina con la nuova data prevista
        </h4>
        <div class="dialog-modal__continue d-flex justify-center">
          <v-btn @click="goHome" color="primary" large class="v-btn--shadowed">
            OK
          </v-btn>
        </div>
      </div>
    </v-dialog>
    <v-row no-gutters class="std-side-padding">
      <v-col>
        <v-row>
          <v-col class="d-flex">
            <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
              <template v-slot:divider>
                <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
              </template>
            </v-breadcrumbs>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pt-10">
            <div class="d-flex align-center">
              <h1 class="font-weight-bold mr-5">{{ title }}</h1>
              <div v-if="timeline.servizio.trim() === 'Gas'">
                <img
                  v-if="timeline.tipoRichiesta.trim() === 'NewConnection'"
                  src="../../assets/icons/info.svg"
                  @click="
                    openDialogHelp('help-timeline-commodity-newactivation')
                  "
                />
                <img
                  v-if="timeline.tipoRichiesta.trim() === 'Reactivation'"
                  src="../../assets/icons/info.svg"
                  @click="
                    openDialogHelp('help-timeline-commodity-reactivation')
                  "
                />
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex align-center">
            <span
              class="face"
              v-if="timeline.tipoRichiesta === 'SwitchIn' && state === 4"
            />
            <h3 class="font-weight-medium" v-html="subTitle" />
          </v-col>
        </v-row>

        <v-row v-if="description.length > 0">
          <v-col>
            <h4
              class="font-weight-light"
              v-html="description"
              v-if="description"
            ></h4>
            <h4 class="font-weight-light" v-else>
              <span class="font-weight-bold" v-show="state === 5"
                >7 giorni lavorativi</span
              >
            </h4>
          </v-col>
        </v-row>

        <v-row v-if="isShowDateBlockText">
          <v-col>
            <div class="std-panel std-panel--shadowed">
              <v-row no-gutters>
                <v-col class="ml-4">
                  <DateBlock :date-text="dateBlockText" />
                </v-col>
                <v-col class="mr-4" v-if="canAnticipate && !isAnticipata">
                  <v-col>
                    <h5 class="font-weight-bold">Vuoi anticiparla?</h5>
                    <h5 class="font-weight-light">
                      Selezionando questa opzione richiedi di avviare
                      anticipatamente la procedura per l’attivazione della tua
                      fornitura…
                      <a
                        @click="speedUpDialog = true"
                        class="scopri-di-piu font-weight-bold"
                        >Scopri di più</a
                      >
                    </h5>
                  </v-col>
                </v-col>
              </v-row>
              <div
                class="anticipa_button d-flex mt-5 font-weight-bold"
                v-if="canAnticipate && !isAnticipata"
              >
                <a v-if="!sendingAnticipationRequest" @click="anticipaClick"
                  >Anticipa l'attivazione</a
                >
                <v-progress-circular
                  v-if="sendingAnticipationRequest"
                  :size="40"
                  indeterminate
                  style="margin: auto"
                ></v-progress-circular>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <TrackingPanel
      class="mt-5"
      fromPage="commodity"
      :address="address"
      :state="state"
      :stateHeaders="stateHeaders"
      :stateDates="stateDates"
      :isSwitchIn="timeline.tipoRichiesta === 'SwitchIn'"
    />
    <v-row v-if="showPromoCode" class="std-side-padding rewards">
      <v-col class="px-3 pb-0">
        <h2 class="mb-4"><strong>Premi per te</strong></h2>
        <div class="d-flex align-center justify-space-between mb-4">
          <div class="desc mb-3">
            Hai ricevuto un codice promozionale e vuoi attivare lo sconto in
            bolletta?
          </div>
        </div>
        <div class="d-flex align-center">
          <div class="inputCode mr-5">
            <v-text-field
              v-model="promoCode"
              placeholder="Inserisci il codice promozionale"
              persistent-placeholder
              @input="v$.promoCode.$touch()"
              :error-messages="verifyCodeErrors"
            />
          </div>
          <v-btn
            x-large
            class="v-btn--shadowed"
            width="264"
            color="primary"
            :rounded="true"
            :disabled="v$.promoCode.$invalid"
            @click="sendCode"
          >
            Conferma
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <DialogContextualHelp
      v-model="dialogHelp"
      :clientCode="$route.params.clientOwnerCode"
      :idContextualHelp="contextualHelpId"
    />
    <v-dialog v-model="dialogPromoCode.show" max-width="750">
      <v-row>
        <v-col class="promo-dialog">
          <img
            class="close"
            @click="dialogPromoCode.show = false"
            src="../../assets/ic_chevron_top_black.png"
          />
          <div class="d-flex align-center justify-center mb-6">
            <img
              class="smile"
              :src="
                dialogPromoCode.result === 'OK'
                  ? require('../../assets/smile-active-green-1.svg')
                  : require('../../assets/ic-istant-win-ko-white.svg')
              "
            />
          </div>
          <h2 class="mb-4" v-if="dialogPromoCode.result === 'OK'">
            Codice inserito correttamente
          </h2>
          <h2 class="mb-4" v-else>
            Ci dispiace! Il servizio al momento non è disponibile
          </h2>
          <div class="desc mb-5" v-if="dialogPromoCode.result === 'OK'">
            L’importo del tuo codice promozionale è di
            <strong>{{ dialogPromoCode.importo }} €.</strong>
          </div>
          <div class="desc mb-8" v-else>
            Ti consigliamo di riprovare più tardi.
          </div>
          <div class="desc mb-8" v-if="dialogPromoCode.result === 'OK'">
            Lo sconto verrà applicato in bolletta e suddiviso in
            {{ dialogPromoCode.nRate }} rate per l’utenza luce di
            <span
              >{{ supplyData.street.toLowerCase() }},
              {{ supplyData.city.toLowerCase() }}</span
            >.
          </div>
          <div class="d-flex justify-center">
            <v-btn
              x-large
              class="white--text v-btn--shadowed"
              width="264"
              color="primary"
              :rounded="true"
              @click="$router.push({ name: 'home' })"
            >
              Torna alla homepage
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-dialog>
  </div>
</template>

<script>
import router from '@/router/router'

import TrackingPanel from '../../components/tracking/TrackingPanel'
import DateBlock from '../../components/tracking/DateBlock'
import GenErr from '@/js/genericErrors'
import { mapActions } from 'vuex'
import { validDate, formatTrackingTimelineDate } from '../../js/utils'
import { getSupplyTrackingParams } from '@/js/trackingUtils'
import DialogContextualHelp from '@/components/contextualHelp/Dialog'
import { hasCharacterValid } from '@/js/utils.js'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { fetchRemoteConfig } from '../../js/service/firebaseService'

export default {
  name: 'TrackingCommodity',
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      loaded: false, // bad solution for redraw screen after request. TODO fix it
      // address: '',
      state: 1,
      stateHeaders: ['Sottoscrizione avvenuta', 'Fornitura attiva'],
      stateDates: ['', ''],
      activationDialog: false,
      speedUpDialog: false,
      sendingAnticipationRequest: false,
      canAnticipate: false,
      clientDetail: {},
      isShowDateBlockText: false,
      timeline: {
        codicePunto: '',
        codiceClienteIntestatario: '',
        tipoClienteIntestatario: '',
        flagEarlyActivation: '',
        codiceProposta: '',
        dataSottoscrizione: '',
        dataScadRipensamento: '',
        dataAttivazione: '',
        dataPrevista: '',
        servizio: '',
        tipoRichiesta: '',
        canale: '',
        stato: ''
      },
      supplyData: {},
      dialogHelp: false,
      isVoltura: false,
      contextualHelpId: '',
      promoCode: '',
      errorPromoCode: null,
      dialogPromoCode: {
        show: false,
        result: '',
        importo: '',
        nRate: ''
      },
      showPromoCode: false
    }
  },
  methods: {
    ...mapActions('tracking', [
      'getTimelineComm',
      'requestCommodityEarlyActivation',
      'getTimelineCommMock'
    ]),
    ...mapActions('account', ['getDetailForClient']),
    ...mapActions('supply', ['getSupplies', 'supplyDetail']),
    ...mapActions('interactions', ['getChannelInfo']),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('MGM', ['sendPromoCode']),

    goHome() {
      router.push({ name: 'home' })
    },
    openDialogHelp(id) {
      this.contextualHelpId = id
      this.dialogHelp = true
    },
    anticipaClick() {
      this.trackTapEvent({
        name: 'supplyTracking_refuseWithdrawal_tap',
        params: getSupplyTrackingParams(this.supplyData)
      })
      if (!this.isAnticipata) {
        this.sendingAnticipationRequest = true

        this.requestCommodityEarlyActivation({
          codiceProposta: this.timeline.codiceProposta,
          username: this.$store.getters['session/username']
        })
          .then((res) => {
            this.activationDialog = res
            this.sendingAnticipationRequest = false
          })
          .catch((error) => {
            GenErr.handleGenericError(error && error.message)
            this.sendingAnticipationRequest = false
          })
      } else {
        this.activationDialog = true
      }
    },
    async setShowPromoCode() {
      await fetchRemoteConfig('timeline_promo_code_available').then(
        (response) => {
          this.showPromoCode = response.paramValue === 'true'
        }
      )
    },
    sendCode() {
      let segmentoCliente =
        (this.supplyData.clientOwnerType === 'RES'
          ? 'Residenziale'
          : 'Business') +
        ' ' +
        (this.clientDetail.hasDigitalPoints ? 'Digital' : 'NoDigital')
      this.sendPromoCode({
        codicePromo: this.promoCode,
        nome:
          this.supplyData.clientOwnerType === 'RES'
            ? this.supplyData.firstName
            : this.supplyData.businessName,
        cognome:
          this.supplyData.clientOwnerType === 'RES'
            ? this.supplyData.lastName
            : this.supplyData.businessName,
        commodity: this.supplyData.serviceType,
        email: this.clientDetail.contactMail,
        indirizzoDiFornitura: this.supplyData.address,
        codiceCliente: this.supplyData.clientOwnerCode,
        segmentoCliente: segmentoCliente,
        codicePunto: this.supplyData.supplyCode
      })
        .then((res) => {
          if (res.status === 'OK' && res.errorCode !== '003') {
            this.errorPromoCode = res
            if (!res.errorCode) {
              this.trackTapEvent({
                name: 'mgmdiscount_thankyoupage_open',
                params: getSupplyTrackingParams(this.supplyData)
              })
              this.dialogPromoCode = {
                show: true,
                result: 'OK',
                importo: res.importo,
                nRate: res.nRate
              }
            }
          } else {
            this.errorPromoCode = res
            this.dialogPromoCode = {
              show: true,
              result: 'KO'
            }
          }
        })
        .catch(() => {
          this.dialogPromoCode = {
            show: true,
            result: 'KO'
          }
        })
    }
  },
  beforeMount() {
    this.setShowPromoCode()
    const identifyState = (timeline) => {
      const computeMonthScad = (refDate, yearScad = 0) => {
        const currentDate = new Date()
        const elevenOfCurrentMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          11
        )

        let month = null
        let year = yearScad

        if (refDate.getTime() < elevenOfCurrentMonth.getTime()) {
          month = currentDate.getMonth()
        } else {
          if (currentDate.getMonth() === 11) {
            month = 0
            year = yearScad + 1
          } else {
            month = currentDate.getMonth() + 1
          }
        }

        return { month, year }
      }

      if (timeline) {
        this.timeline = timeline

        if (this.timeline.tipoRichiesta === 'SwitchInConVoltura') {
          this.isVoltura = true
          this.timeline.tipoRichiesta = 'SwitchIn'
        }
        this.isShowDateBlockText = this.timeline.tipoRichiesta === 'SwitchIn'

        this.stateDates = [
          formatTrackingTimelineDate(this.timeline.dataSottoscrizione),
          formatTrackingTimelineDate(this.timeline.dataAttivazione)
        ]

        let dataPrevista = ''
        let currentDate = new Date()
        let anticipationRequests = JSON.parse(
          localStorage.getItem('anticipationRequests')
        )
        timeline.flagEarlyActivation =
          this.isVoltura ||
          (anticipationRequests &&
            anticipationRequests[timeline.codiceProposta]) ||
          !!timeline.flagEarlyActivation
        let dataSottoscriz = new Date(timeline.dataSottoscrizione)
        let dataScadRipensamento = null
        if (timeline.canale === 'WEB BUSINESS') {
          dataScadRipensamento = dataSottoscriz
        } else {
          dataScadRipensamento = new Date(
            dataSottoscriz.getTime() +
              (timeline.flagEarlyActivation ? 0 : 14 * 24 * 60 * 60 * 1000)
          ) // adding 14 days if glagEarlyActivation
        }
        // dataScadRipensamento = validDate(timeline.dataScadRipensamento) ? new Date(timeline.dataScadRipensamento.replace(/\//g, '-')) : dataScadRipensamento

        let monthScadPrevista = null
        let yearScad = dataScadRipensamento.getFullYear()

        let res = computeMonthScad(dataScadRipensamento, yearScad)
        monthScadPrevista = res.month
        yearScad = res.year

        // dataPrevista = timeline.flagEarlyActivation ? new Date(timeline.dataPrevistaAttivazione.replace(/\//g, '-')) : new Date(yearScad, monthScadPrevista + 1, 1)
        dataPrevista = validDate(timeline.dataPrevistaAttivazione)
          ? new Date(timeline.dataPrevistaAttivazione.replace(/\//g, '-'))
          : new Date(yearScad, monthScadPrevista + 1, 1)

        this.timeline.dataPrevista =
          (dataPrevista.getDate() < 10
            ? '0' + dataPrevista.getDate()
            : dataPrevista.getDate()) +
          '.' +
          (dataPrevista.getMonth() + 1 < 10
            ? '0' + parseInt(dataPrevista.getMonth() + 1)
            : parseInt(dataPrevista.getMonth() + 1)) +
          '.' +
          dataPrevista.getFullYear()

        if (
          (dataSottoscriz.getDate() >= 28 &&
            currentDate.getTime() >= new Date(dataSottoscriz).setDate(28) &&
            currentDate.getTime() <=
              new Date(
                new Date(dataSottoscriz).setMonth(dataSottoscriz.getMonth() + 1)
              ).setDate(10)) ||
          (dataSottoscriz.getDate() <= 10 &&
            currentDate.getTime() <= new Date(dataSottoscriz).setDate(10) &&
            currentDate.getTime() >=
              new Date(
                new Date(dataSottoscriz).setMonth(dataSottoscriz.getMonth() - 1)
              ).setDate(28))
        ) {
          this.canAnticipate = true
        }

        if (timeline.tipoRichiesta === 'SwitchIn') {
          this.stateHeaders.splice(1, 0, 'Avvio<br>lavori')
          this.stateHeaders.splice(2, 0, 'In conferma di attivazione')
        }
        let dataAccelerazione = !timeline.flagEarlyActivation
          ? dataScadRipensamento
          : new Date(timeline.dataAccelerazione)

        let printData =
          (dataAccelerazione.getDate() < 10
            ? '0' + dataAccelerazione.getDate()
            : dataAccelerazione.getDate()) +
          '.' +
          (dataAccelerazione.getMonth() + 1 < 10
            ? '0' + parseInt(dataAccelerazione.getMonth() + 1)
            : parseInt(dataAccelerazione.getMonth() + 1)) +
          '.' +
          dataAccelerazione.getFullYear()

        let isAccettazione =
          this.timeline.stato === 'ACCETTATO' &&
          (this.timeline.canale === 'TELESELLER' ||
            this.timeline.canale === 'PMI')
        if (validDate(this.timeline.dataAccettazione) && isAccettazione) {
          let dataAccettazione = new Date(this.timeline.dataAccettazione)
          printData =
            (dataAccettazione.getDate() < 10
              ? '0' + dataAccettazione.getDate()
              : dataAccettazione.getDate()) +
            '.' +
            (dataAccelerazione.getMonth() + 1 < 10
              ? '0' + parseInt(dataAccettazione.getMonth() + 1)
              : parseInt(dataAccettazione.getMonth() + 1)) +
            '.' +
            dataAccettazione.getFullYear()
          dataAccelerazione = dataAccettazione
        }
        if (validDate(timeline.dataAttivazione)) {
          this.state = 4
          this.isShowDateBlockText = false
          if (timeline.tipoRichiesta === 'SwitchIn') {
            this.stateDates.splice(1, 0, printData)
            this.stateDates.splice(2, 0, this.timeline.dataPrevista)
          }
        } else if (timeline.tipoRichiesta === 'SwitchIn') {
          if (currentDate >= dataPrevista) {
            if (this.timeline.stato === 'ACCETTATO') {
              this.state = 3
              this.stateDates.splice(1, 0, printData)
              this.stateDates.splice(2, 0, this.timeline.dataPrevista)
            } else {
              if (this.isVoltura) {
                this.state = 2
              } else {
                this.state = 1
              }
            }
          } else if (
            currentDate >= dataAccelerazione &&
            currentDate < dataPrevista
          ) {
            if (this.timeline.stato === 'ACCETTATO') {
              this.state = 2
              this.stateDates.splice(1, 0, printData)
            } else {
              if (this.isVoltura) {
                this.state = 2
              } else {
                this.state = 1
              }
            }
          } else if (this.timeline.canale === 'TELESELLER') {
            this.canAnticipate = false
            if (this.timeline.stato === 'ACCETTATO') {
              this.state = 2
              this.stateDates.splice(1, 0, printData)
            } else {
              if (this.isVoltura) {
                this.state = 2
              } else {
                this.state = 1
              }
            }
          } else if (currentDate < dataAccelerazione) {
            if (this.isVoltura) {
              this.state = 2
            } else {
              this.state = 1
            }
          }
        } else {
          if (
            timeline.servizio === 'Ele' &&
            timeline.tipoRichiesta === 'Reactivation'
          ) {
            this.state = 6
          } else {
            this.state = 5
          }
        }
      }
    }
    if (this.$route.params.supplyCode.startsWith('mock')) {
      // Dev stuff
      this.getTimelineCommMock()
        .then((data) => {
          let found = data.find((v) => {
            return v.codicePunto === this.$route.params.supplyCode
          })
          identifyState(found)
          this.loaded = true
        })
        .catch((error) => {
          GenErr.handleGenericError(error && error.message)
          GenErr.showErrorMessage()
        })
    } else {
      this.getTimelineComm()
        .then((data) => {
          const timelines = data.array
          if (timelines.length > 0) {
            let found = timelines.find((v) => {
              return v.codicePunto === this.$route.params.supplyCode
            })
            identifyState(found)
            this.loaded = true
          }
        })
        .catch((error) => {
          GenErr.handleGenericError(error && error.message)
          GenErr.showErrorMessage()
        })
    }

    this.getSupplies()
      .then((resp) => {
        resp.forEach((v) => {
          if (v.supplyCode === this.$route.params.supplyCode) {
            this.supplyData = v
          }
        })
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })

    this.getDetailForClient({
      clientOwnerCode: this.$route.params.clientOwnerCode
    }).then((resp) => {
      this.clientDetail = resp
    })
  },
  computed: {
    breadcrumbsItems() {
      let res = [
        { to: { name: 'home' }, text: 'Home' },
        {
          to: { name: this.$router.currentRoute.name },
          text: 'Stato attivazione'
        }
      ]

      return res
    },
    isAnticipata() {
      let anticipationRequests = JSON.parse(
        localStorage.getItem('anticipationRequests')
      )
      return (
        (anticipationRequests &&
          anticipationRequests[this.timeline.codiceProposta]) ||
        this.timeline.flagEarlyActivation
      )
    },
    service() {
      return this.timeline.servizio === 'Ele' ? 'Luce' : 'Gas'
    },
    dateBlockText() {
      let text = this.isAnticipata
        ? 'Data prevista di attivazione: ' +
          formatTrackingTimelineDate(this.timeline.dataPrevista)
        : 'Data prevista di attivazione: ' +
          formatTrackingTimelineDate(this.timeline.dataPrevista)
      return text
    },
    title() {
      if (this.state !== 4) {
        return 'Attivazione ' + this.service
      } else {
        return 'Evviva! Si Parte!'
      }
    },
    subTitle() {
      switch (this.state) {
        case 1:
          return 'Non devi fare nulla, pensiamo a tutto noi!'
        case 2:
          return 'Stiamo lavorando per te!'
        case 3:
          return 'Ci siamo quasi!'
        case 4:
          return this.timeline.tipoRichiesta === 'SwitchIn'
            ? 'La tua fornitura<br/>è attiva.'
            : 'Perfetto!'
        case 5:
          return "Non devi fare nulla!<br/>Ti contatteremo noi per seguire insieme tutti i passaggi necessari all'attivazione."
        case 6:
          return 'Non devi fare nulla, pensiamo a tutto noi!'
        default:
          return ''
      }
    },
    description() {
      switch (this.state) {
        case 1:
          return this.timeline.canale !== 'TELESELLER'
            ? 'Verrai contattato da un nostro operatore per confermare l’avvio del contratto!'
            : ''
        case 2:
          return 'Abbiamo avviato il processo di attivazione del tuo contratto con Sorgenia.<br/>Qui troverai tutti gli aggiornamenti necessari!'
        case 3:
          return this.timeline.tipoRichiesta === 'SwitchIn'
            ? "Stiamo completando le ultime verifiche sulla tua fornitura.<br/>A breve ti daremo conferma dell'avvenuta attivazione."
            : "Stiamo completando gli ultimi passi per attivare la tua fornitura con Sorgenia. A breve ti potremo confermare l'avvenuta attivazione."
        case 4:
          return ''
        case 5:
          return ''
        case 6:
          return 'La procedura di attivazione dura almeno <strong>7 giorni lavorativi</strong>'
        default:
          return ''
      }
    },
    verifyCodeErrors() {
      let errArray = []

      if (!this.v$.promoCode.$dirty) return errArray

      this.v$.promoCode.required.$invalid === true &&
        errArray.push('Il codice promozionale è obbligatorio')
      this.v$.promoCode.hasCharacterValid === false &&
        errArray.push('Non sono ammessi caratteri speciali')
      if (this.errorPromoCode) {
        if (this.errorPromoCode.errorCode === '001') {
          errArray.push('Il codice è già stato utilizzato')
        } else if (this.errorPromoCode.errorCode === '002') {
          errArray.push('Il codice non è più valido')
        } else if (this.errorPromoCode.errorCode === '003') {
          errArray.push(
            'Al momento il servizio non è disponibile. Riprova più tardi'
          )
        }
      }

      return errArray
    },
    address() {
      return this.supplyData.street + ', ' + this.supplyData.city
    }
  },
  components: {
    TrackingPanel,
    DateBlock,
    DialogContextualHelp
  },
  validations: {
    promoCode: { required, hasCharacterValid }
  }
}
</script>

<style lang="scss" scoped>
.no_result {
  background-color: #f6f6f6;
  min-height: 150px;
  display: flex;
  justify-content: center;

  p {
    align-self: center;
    margin: 0;
  }
}

.dialog-modal {
  background: white;
  border-radius: 40px;
  padding: 48px 48px 48px 48px;

  &__title {
    font-weight: bold;
    font-size: 32px;
    line-height: 1.1em !important;
  }

  &__title_small {
    font-weight: bold;
    font-size: $title-font-size;
  }

  &__text {
    font-size: $std-font-size;
  }

  &__continue {
    font-weight: bold;
    font-size: $std-font-size;
    margin-top: 24px;

    button {
      width: 264px;
    }
  }
}

.std-panel {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.anticipa_button {
  border-top: 1px solid $srg-lightgray;
  height: 70px;

  a {
    margin: auto;
    color: $srg-blue;
    font-size: 18px;
  }
}

.scopri-di-piu {
  color: $srg-blue;
  font-size: 16px;
}
.rewards {
  background: #eaeef5;
  padding-bottom: 30px;
  color: black;
  padding-top: 38px;

  h5 {
    font-weight: 500;
    cursor: pointer;
  }

  .desc {
    font-size: 20px;
  }

  .inputCode {
    width: 455px;
  }
}
.face::before {
  content: url('../../assets/ic-istant-win-ok.png');
  width: 89px;
  margin-right: 20px;
}
.promo-dialog {
  background: white;
  padding: 55px 45px 45px 55px;
  border-radius: 40px;
  position: relative;

  .small {
    padding: 55px 110px 55px 55px;
  }

  h2 {
    font-weight: bold;
    margin-bottom: 20px;
  }

  .desc {
    font-size: 20px;

    span {
      text-transform: capitalize;
    }
  }

  .close {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;

    &.preview {
      position: initial;
    }
  }
}
</style>
