var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"offers mb-12 pt-10"},[_c('div',{staticClass:"offers__content std-side-padding topRadius pt-8"},[_c('ProgressBar',{attrs:{"value":15}}),_c('div',{staticClass:"offers__back mb-8",on:{"click":function($event){return _vm.$router.push({ name: _vm.type === 'ele' ? 'eleOffering' : 'gasOffering' })}}},[_c('img',{attrs:{"src":require("../../../assets/ic-chevron-sx-blue.svg"),"width":"24"}}),_c('div',{staticClass:"HeadingM",staticStyle:{"color":"#12256a"}},[_vm._v(" Offerta "+_vm._s(_vm.type === 'ele' ? 'Luce' : 'Gas')+" ")])]),_c('div',{staticClass:"d-flex align-start"},[(!_vm.loaded)?_c('div',{staticClass:"skeletonUpselling"},[_c('transition',{attrs:{"name":"fade"}},[_c('Placeholder',{attrs:{"type":"upsellingAnteprima"}})],1),_c('transition',{attrs:{"name":"fade"}},[_c('Placeholder',{attrs:{"type":"upsellingAnteprimaNoBg"}})],1)],1):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.loaded)?_c('div',[_c('div',{staticClass:"customContainer"},[_c('div',{staticClass:"offers__offer"},[_c('div',{staticClass:"offerHeader"},[_c('div',{staticClass:"SubheadingL"},[_vm._v(" "+_vm._s(_vm.product.productTitle)+" ")]),_c('img',{attrs:{"contain":"","src":_vm.type === 'ele'
                      ? require('../../../assets/icons/ic-light-blu.svg')
                      : require('../../../assets/icons/ic-fire-blu.svg'),"width":"32","height":"32"}})]),_c('div',{staticClass:"Price"},[_c('div',{staticClass:"BodyM"},[_vm._v(" "+_vm._s(_vm.product.priceDetail)+" ")]),_c('div',{staticClass:"ButtonM detail",on:{"click":function($event){return _vm.openPriceDetail()}}},[_vm._v(" "+_vm._s(_vm.product.priceDetailText)+" ")])]),(_vm.benefits.benefitList)?_c('v-divider',{staticClass:"divider"}):_vm._e(),(_vm.benefits.benefitList)?_c('div',{staticClass:"detailOffer"},[_c('div',{staticClass:"BodyL-Strong mb-4"},[_vm._v(" "+_vm._s(_vm.benefits.benefitTitle)+" ")]),_vm._l((_vm.benefits.benefitList),function(benefit,i){return _c('div',{key:i},[(
                      (_vm.type === 'ele' && benefit.electricityFlag) ||
                      (_vm.type === 'gas' && benefit.gasFlag)
                    )?_c('div',{staticClass:"benefit"},[_c('img',{attrs:{"src":_vm.loadIconClass(benefit.benefitIconClass),"width":"24"}}),_c('div',{staticClass:"BodyL",domProps:{"innerHTML":_vm._s(benefit.title)}}),(benefit.deepDiveModalFlag)?_c('img',{staticClass:"imgInfo",attrs:{"src":require("../../../assets/upselling/icons/icon-info.svg"),"width":"24"},on:{"click":function($event){return _vm.openCustomDialog(
                          benefit.imageModal,
                          benefit.modalText,
                          benefit.modalTitle
                        )}}}):_vm._e()]):_vm._e()])})],2):_vm._e()],1),_c('div',{staticClass:"LabelM"},[_vm._v(" "+_vm._s(_vm.catalogList.endDateValidityFinalText)+" ")])])]):_vm._e()])],1),_c('div',{staticClass:"cta"},[_c('MYSButton',{attrs:{"disabled":!_vm.loaded,"buttonText":"Scopri l’offerta","buttonClass":"primaryButtonL"},on:{"buttonClick":function($event){return _vm.next()}}}),_c('div',{staticClass:"ButtonL callme",on:{"click":_vm.goToSpeakWith}},[_vm._v(" Parla con un consulente ")])],1)],1),_c('CustomDialog',{attrs:{"customDialogOpen":_vm.customDialogOpen,"body":_vm.bodyModal,"title":_vm.titleModal,"image":_vm.imageModal,"showImage":_vm.showImage},on:{"update:customDialogOpen":function($event){_vm.customDialogOpen=$event},"update:custom-dialog-open":function($event){_vm.customDialogOpen=$event}}}),_c('ServiceUnavailableDialog',{attrs:{"serviceUnavailableDialogOpen":_vm.serviceUnavailableDialogOpen,"imageResponse":_vm.imageServiceError,"title":_vm.titleServiceError,"body":_vm.bodyServiceError,"cta1":"Riprova ora","cta2":"Torna alla homepage"},on:{"update:serviceUnavailableDialogOpen":function($event){_vm.serviceUnavailableDialogOpen=$event},"update:service-unavailable-dialog-open":function($event){_vm.serviceUnavailableDialogOpen=$event},"retry":_vm.loadOfferPreview,"returnToHome":_vm.returnToHome}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }