<template>
  <SpeedTest
    :isAbstract="promo.abstract !== ''"
    v-if="
      promo &&
      promo.cta_action_type === 'APP_SECTION' &&
      promo.cta &&
      APP_SECTION_MAPPING[promo.app_section_section] === 'speedTest'
    "
  >
    <slot name="contentPromo" />
  </SpeedTest>

  <div
    class="handCursor"
    v-else-if="
      promo &&
      promo.cta_action_type === 'APP_SECTION' &&
      promo.cta &&
      APP_SECTION_MAPPING[promo.app_section_section] === 'carbonFootprint'
    "
    @click="openFootprint()"
  >
    <slot name="contentPromo" />
  </div>

  <router-link
    v-else-if="promo && promo.cta && hasLandingPage(promo)"
    :to="{ name: 'promo', params: { id: promo.id.substring(1) } }"
  >
    <slot name="contentPromo" />
  </router-link>

  <router-link
    v-else-if="promo && promo.cta_action_type === 'APP_SECTION' && promo.cta"
    :to="{
      name: APP_SECTION_MAPPING[promo.app_section_section],
      params: { from: 'homepage-promo' }
    }"
  >
    <slot name="contentPromo" />
  </router-link>

  <router-link
    v-else-if="
      promo && promo.app_section_section.toLowerCase() === 'welcomekit'
    "
    :to="{
      name: APP_SECTION_MAPPING[promo.app_section_section.toLowerCase()],
      params: { from: 'homepage-promo' }
    }"
  >
    <slot name="contentPromo" />
  </router-link>

  <a
    v-else-if="promo && promo.cta_action_type === 'WEB_LINK'"
    :href="promo.url"
    target="_blank"
  >
    <slot name="contentPromo" />
  </a>
  <!-- Aggiunto blocco fallback -->
  <div v-else-if="promo">
    <slot name="contentPromo" />
  </div>
</template>
<script>
import { APP_SECTION_MAPPING } from '@/js/constants'

import SpeedTest from '@/components/speedTest/'

import { eventBus } from '@/main'

export default {
  name: 'promoRouting',
  components: {
    SpeedTest
  },
  props: {
    promo: { type: Object }
  },
  data() {
    return {
      APP_SECTION_MAPPING: APP_SECTION_MAPPING
    }
  },
  methods: {
    hasLandingPage(promo) {
      return (
        promo.media_id !== '' &&
        promo.media_title !== '' &&
        promo.media_file !== '' &&
        promo.media_type !== ''
      )
    },
    openFootprint() {
      eventBus.$emit('footprint:open')
    }
  }
}
</script>

<style scoped>
.handCursor {
  cursor: pointer;
}
</style>
