<template>
  <div class="information">
    <TcVars />
    <div class="information__container-minimal-info bkg--lightgray">
      <div class="HeadingsTitleM">
        <p>
          {{ $filters.capitalize(clientDetails.name) }}
          {{ $filters.capitalize(clientDetails.surname) }}
        </p>
      </div>
      <div class="d-flex justify-space-between">
        <div class="information__section-info">
          <h5 class="BodyL">Codice Fiscale</h5>
          <h5 class="BodyL-Strong">
            {{ clientDetails.fiscalCode || 'N/A' }}
          </h5>
        </div>
        <div
          class="information__section-info"
          v-if="
            (filteredClientDetails.clientOwnerCode &&
              !filteredClientDetails.clientCodeFV) ||
            (filteredClientDetails.clientOwnerCode &&
              filteredClientDetails.clientCodeFV)
          "
        >
          <h5 class="BodyL">Codice Cliente</h5>
          <h5 class="BodyL-Strong" v-if="filteredClientDetails.clientOwnerCode">
            {{ clientDetails.clientCode || 'N/A' }}
          </h5>
        </div>
        <div class="information__section-info">
          <h5 class="BodyL">Utente</h5>
          <h5 class="BodyL-Strong">
            {{
              clientDetails.clientType === 'RES'
                ? 'Residenziale'
                : 'Business' || 'N/A'
            }}
          </h5>
        </div>
      </div>
    </div>
    <div class="information__container-title-email HeadingsTitleM">
      <span>Dati Account</span>
    </div>
    <div class="information__container-email-info bkg--lightgray">
      <div class="information__email-info">
        <h5 class="BodyL mb-1">Email di acceso alle APP Sorgenia</h5>
        <h5 class="BodyL-Strong">{{ username || 'N/A' }}</h5>
      </div>
      <div class="information__password">
        <h5 class="BodyL mb-1">Password</h5>
        <h5
          @click="pwdDialog = true"
          class="BodyL-Underline color--blue change-psw"
        >
          Modifica
        </h5>
      </div>
    </div>
    <div class="information__container-personal-title HeadingsTitleM">
      <p>I tuoi dati personali</p>
    </div>
    <div class="information__container-personal mb-0">
      <div class="single-section custom-input-field pb-10">
        <v-text-field
          tabindex="1"
          class="auth__input readOnly"
          label=""
          v-model.trim="mailContatto"
          readonly
        >
          <template v-slot:label>
            <div class="d-flex align-center">
              <span>EMAIL DI CONTATTO</span>
              <img
                style="cursor: pointer"
                @click="mailDialog = true"
                class="ml-2"
                src="../../assets/icons/info.svg"
              />
            </div>
          </template>
        </v-text-field>
      </div>
    </div>
    <div class="information__container-personal">
      <div class="single-section custom-input-field">
        <v-text-field
          tabindex="1"
          :error-messages="phoneErrors"
          @blur="v$.telefonoCellulare.$touch()"
          class="auth__input"
          clearable
          label="TELEFONO"
          color="#6e7689"
          v-model.trim="telefonoCellulare"
          type="number"
          @click="checkEmail"
          :disabled="
            !filteredClientDetails.clientOwnerCode &&
            !!filteredClientDetails.clientCodeFV
          "
        />
      </div>
      <div class="single-section custom-input-field search-input">
        <!-- <VueGooglePlaces
          :api-key="googlePlacesKey"
          types="address"
          country="it"
          placeholder="Input your place"
          @placechanged="getAddressData"
        >
          <v-text-field
            v-model.trim="address"
            :value="addressObject"
            :error-messages="addressErrors"
            @blur="v$.address.$touch()"
            @input="addressObject = address"
            tabindex="1"
            class="auth__input"
            label="INDIRIZZO DI RESIDENZA"
            color="#6e7689"
            @click="checkEmail"
          >
            <template v-slot:append>
              <img src="@/assets/profile/search.svg" />
            </template>
          </v-text-field>
        </VueGooglePlaces> -->
        <vuetify-google-autocomplete
          ref="address"
          id="map"
          classname="auth__input"
          placeholder="Input your place"
          v-on:placechanged="getAddressData"
          country="it"
          label="INDIRIZZO DI RESIDENZA"
          v-model.trim="address"
          :value="addressObject"
          @click="checkEmail"
          :error-messages="addressErrors"
          @blur="v$.address.$touch()"
          :disabled="
            !filteredClientDetails.clientOwnerCode &&
            !!filteredClientDetails.clientCodeFV
          "
        >
          <template #append>
            <img src="@/assets/profile/search.svg" />
          </template>
        </vuetify-google-autocomplete>
      </div>
    </div>
    <!-- <span class="ButtonM deleteAccount color--blue" @click="deleteProfile"
      >Elimina il tuo profilo</span
    > -->
    <span
      :class="
        !clientDetails.accountDeletionRequested
          ? 'ButtonM deleteAccount color--blue'
          : 'ButtonM accountDeleted'
      "
      @click="deleteProfile"
      >Elimina il tuo profilo</span
    >
    <div
      class="information__container-button"
      v-if="
        filteredClientDetails.clientOwnerCode &&
        !filteredClientDetails.clientCodeFV
      "
    >
      <MYSButton
        :disabled="
          v$.$invalid ||
          addressErrors.length > 0 ||
          clientDetails.accountDeletionRequested
        "
        button-class="primaryButtonS"
        @buttonClick="updateProfile"
        :loading="sendingEdit"
        button-text="Aggiorna"
      />
      <MYSButton
        :disabled="clientDetails.accountDeletionRequested"
        :button-class="
          clientDetails.accountDeletionRequested
            ? 'ml-6 primaryInvertedButtonS'
            : 'primaryInvertedButtonBorded ml-6'
        "
        @buttonClick="cancelUpdate"
        button-text="Annulla"
      />
    </div>
    <DeleteUser v-model="dialogDeleteUser" :clientDetail="clientDetails" />
    <v-dialog v-model="pwdDialog" max-width="576">
      <div class="ripensamento-modal">
        <!-- <img
          src="@/assets/ic_chevron_top_black.png"
          alt="close"
          class="custom-close"
          @click="pwdDialog = false"
        /> -->
        <change-pwd
          :bus="bus"
          modalMode
          @closeModalNoChange="pwdDialog = false"
          @closeModal="passwordChanged"
        />
      </div>
    </v-dialog>
    <v-dialog v-model="pwdChangedDialog" max-width="490">
      <div class="ripensamento-modal">
        <div>
          <div class="d-flex">
            <div class="ripensamento-modal__title">Password modificata!</div>
            <img
              src="@/assets/ic_chevron_top_black.png"
              alt="close"
              class="custom-close mt-2"
              @click="pwdChangedDialog = false"
            />
          </div>
          <div class="ripensamento-modal__text">
            La tua password è stata modificata. Non dimenticarlo al prossimo
            accesso!
          </div>
          <div class="ripensamento-modal__continue full-bt-group">
            <MYSButton
              button-class="primaryButton"
              @buttonClick="pwdChangedDialog = false"
              button-text="Torna all'account"
            />
          </div>
        </div>
      </div>
    </v-dialog>
    <EditContactMailVue
      :mailDialog.sync="mailDialog"
      :user="userProfile.clientOwnerCode"
    />
  </div>
</template>
<script>
import TcVars from '@/components/tcTracker/TcVars'
import DeleteUser from '@/components/user/DeleteUser.vue'
import { AlertService } from '@/js/service/alertService'
import ChangePwd from '@/views/ChangePwd'
import { email, maxLength, minLength, required } from '@vuelidate/validators'
import { mapActions, mapGetters } from 'vuex'
import EditContactMailVue from '../../components/support/EditContactMail.vue'

import { ENV_CONFIGS } from '@/js/configs'
import { capitalize } from '@/js/utils.js'
import { eventBus } from '@/main.js'
// import { VueGooglePlaces } from 'vue-google-places'

import { useVuelidate } from '@vuelidate/core'
import MYSButton from '../../components/global/MYSButton.vue'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'informationProfile',
  components: {
    DeleteUser,
    // VueGooglePlaces,
    ChangePwd,
    EditContactMailVue,
    TcVars,
    MYSButton
  },
  data() {
    return {
      mailContatto: '',
      telefonoCellulare: '',
      address: '',
      addressObject: '',
      dialogDeleteUser: false,
      sendingEdit: false,
      pwdDialog: false,
      pwdChangedDialog: false,
      mailDialog: false,
      bus: eventBus
    }
  },
  watch: {
    userProfile() {
      this.detailForClient()
    }
  },
  computed: {
    ...mapGetters('account', ['clientDetails', 'filteredClientDetails']),
    ...mapGetters('session', ['username', 'getAccountDeletionRequested']),
    ...mapGetters('profile', ['userProfile']),

    googlePlacesKey() {
      return ENV_CONFIGS.GOOGLE_PLACES_KEY
    },
    phoneErrors() {
      let errArray = []
      if (!this.v$.telefonoCellulare.$dirty) return errArray
      this.v$.telefonoCellulare.minLength.$invalid === true &&
        errArray.push('Il numero di telefono deve essere di almeno 6 cifre')
      this.v$.telefonoCellulare.maxLength.$invalid === true &&
        errArray.push('Il numero di telefono può avere al massimo 12 cifre')
      this.v$.telefonoCellulare.required.$invalid === true &&
        errArray.push('Il Numero di telefono è obbligatorio')
      return errArray
    },
    mailContattoErrors() {
      let errArray = []
      if (!this.v$.mailContatto.$dirty) return errArray
      this.v$.mailContatto.email.$invalid === true &&
        errArray.push('Formato email non valido')
      this.v$.mailContatto.sameAsEmail === false &&
        errArray.push("L'email è obbligatoria")
      return errArray
    },
    addressErrors() {
      let errArray = []
      if (!this.v$.address.$dirty) return errArray
      this.v$.address.required.$invalid === true &&
        errArray.push("L'indirizzo è obbligatorio")
      typeof this.addressObject === 'string' &&
        errArray.push('Seleziona un indirizzo valido')
      !this.addressObject.street_number &&
        errArray.push('Il numero civico non è presente')
      !this.addressObject.route && errArray.push('La via non è presente')
      !this.addressObject.postal_code && errArray.push('Il CAP non è presente')
      !this.addressObject.locality && errArray.push('La città non è presente')
      return errArray
    }
  },
  mounted() {
    this.detailForClient()
  },
  methods: {
    ...mapActions('account', ['getDetailForClient', 'editcontent']),
    detailForClient() {
      this.getDetailForClient(this.userProfile).then((client) => {
        this.mailContatto = client.contactMail || ''
        this.telefonoCellulare = client.mobilePhone
        this.address = capitalize(
          `${client.residentialAddress}, ${client.residentialCAP}, ${client.residentialCity}`
        )
      })
    },
    getAddressData(addressData) {
      this.addressObject = addressData
      this.address = `${addressData.route || ''} ${
        addressData.street_number || ''
      }, ${addressData.postal_code || ''}, ${addressData.locality || ''}`
      this.$forceUpdate()
    },
    deleteProfile() {
      this.dialogDeleteUser = true
    },
    async updateProfile() {
      this.sendingEdit = true
      let addressParam =
        this.addressObject.route && this.addressObject.street_number
          ? `${this.addressObject.route || ''} ${
              this.addressObject.street_number || ''
            }`
          : ''
      let params = {
        codiceCliente: this.clientDetails.clientCode,
        street: addressParam || this.clientDetails.residentialAddress,
        zipCode:
          this.addressObject.postal_code || this.clientDetails.residentialCAP,
        city: this.addressObject.locality || this.clientDetails.residentialCity,
        provincial:
          this.addressObject.administrative_area_level_2 ||
          this.clientDetails.residentialProvince,
        nation:
          this.addressObject.country || this.clientDetails.residentialNation,
        phone: this.clientDetails.phone,
        mobilePhone: this.telefonoCellulare || this.clientDetails.mobilePhone,
        email: this.mailContatto,
        marketDenial: !!this.clientDetails.marketingConsentFlag,
        profilazione: !!this.clientDetails.profilingConsentFlag,
        channel: 'Web'
      }
      this.editcontent(params)
        .then(() => {
          this.sendingEdit = false
        })
        .catch(() => {
          this.sendingEdit = false
          AlertService(
            'Servizio non disponibile',
            'Al momento non è possibile salvare le modifiche al profilo. Ti invitiamo a riprovare.',
            false,
            'Chiudi',
            '#'
          )
        })
    },
    cancelUpdate() {
      this.mailContatto = this.clientDetails.contactMail
      this.telefonoCellulare = this.clientDetails.mobilePhone
      this.address = `${this.clientDetails.residentialAddress}, ${this.clientDetails.residentialCAP}, ${this.clientDetails.residentialCity}`
    },
    passwordChanged() {
      this.pwdDialog = false
      this.pwdChangedDialog = true
    },
    checkEmail() {
      if (!this.mailContatto) {
        AlertService(
          'Modifica dei dati personali',
          "Per poter procedere con la modifica dei tuoi dati è necessario inserire anche la tua email di contatto. Puoi richiedere l’aggiunta di questa email e la modifica degli altri dati contattando l'assistenza.",
          'alert',
          'Contatta l’assistenza',
          'Contacts',
          false,
          false,
          { user: this.userProfile.clientOwnerCode },
          false
        )
      }
    }
  },
  validations: {
    mailContatto: { required, email },
    telefonoCellulare: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(12)
    },
    address: { required }
  }
}
</script>
<style lang="scss">
.readOnly.theme--light.v-input input {
  color: black !important;
}
.readOnly.theme--light.v-input label {
  pointer-events: auto;
}
</style>
<style lang="scss" scoped>
.information {
  width: 815px;
  margin-bottom: 47px;

  &__container-minimal-info {
    border-radius: 8px;
    padding: 24px 0;
    margin-bottom: 40px;

    p {
      padding-left: 56px;
      position: relative;
      &:before {
        content: '';
        width: 18px;
        height: 18px;
        position: absolute;
        left: 20px;
        top: 0px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../../assets/profile/profile_icon.svg');
      }
    }
  }
  &__section-info {
    max-width: 170px;
    width: 100%;

    &:first-child {
      padding-left: 56px;
    }
  }
  &__container-title-email {
    margin-bottom: 16px;
  }
  &__container-email-info {
    border-radius: 8px;
    padding: 24px 0;
    margin-bottom: 40px;
    display: flex;
    align-content: center;
  }
  &__email-info {
    position: relative;
    width: 50%;
    padding-left: 56px;
    &:before {
      content: url('../../assets/profile/mail.svg');
      position: absolute;
      left: 20px;
      top: 18px;
    }
  }
  &__password {
    position: relative;
    width: 50%;
    padding-left: 16px;
    &:before {
      content: url('../../assets/profile/password.svg');
      position: absolute;
      left: -20px;
      top: 15px;
    }
    .change-psw {
      cursor: pointer;
    }
  }
  &__container-personal-title {
    margin-bottom: 40px;
  }
  &__container-personal {
    margin-bottom: 48px;
    display: flex;
    flex-wrap: wrap;
    .single-section {
      max-width: 362px;
      width: 100%;
      margin-right: 32px;
    }
  }

  .search-input img {
    cursor: pointer;
  }
  .deleteAccount {
    cursor: pointer;
    position: relative;
    &:after {
      content: '';
      width: 18px;
      height: 18px;
      margin-left: 10px;
      right: -30px;
      top: 0;
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('../../assets/profile/trash.svg');
    }
  }
  &__container-button {
    margin-top: 48px;
  }
  .accountDeleted {
    pointer-events: none;
    position: relative;
    color: #b7bfd2;
    &:after {
      content: '';
      width: 18px;
      height: 18px;
      margin-left: 10px;
      right: -30px;
      top: 0;
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('../../assets/profile/trashDisabled.svg');
    }
  }
}
</style>
