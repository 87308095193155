<template>
  <div>
    <v-dialog
      v-model="dialogOpen"
      persistent
      max-width="558"
      overlay-opacity="0.6"
      content-class="selfReadingModal"
    >
      <div v-if="loaded" class="selfReadingModal">
        <div class="titleModal">
          <div class="SubheadingL">
            Seleziona l’indirizzo per cui inviare l’autolettura
          </div>
          <img
            class="close"
            src="@/assets/ic_chevron_top_black.png"
            alt="close"
            @click="$emit('update:selfReadingModalOpen', false)"
          />
        </div>
        <div
          v-for="(name, i) in Object.keys(filteredSupplies)"
          :key="i"
          class="clients"
        >
          <img src="" alt="" />
          <div
            :class="`BodyL-Strong name ${filteredSupplies[
              name
            ][0].clientOwnerType.toLowerCase()}`"
          >
            {{ name }}
          </div>
          <div
            v-for="(address, a) in filteredSupplies[name]"
            :key="a"
            class="supplies"
            @click="ctaTrigger(address)"
          >
            <div class="label">
              <div class="content">
                <img
                  cla
                  :src="pathImg(address.serviceType)"
                  width="25"
                  class="mr-4"
                />
                <div class="text">
                  <div class="address BodyL">
                    {{ capitalizeAddress(address.street) }},
                    {{ capitalizeAddress(address.city) }}
                  </div>
                  <div class="LabelM">INVIA AUTOLETTURA</div>
                </div>
              </div>
              <img src="../../assets/icons/chevron_rx.svg" />
            </div>
          </div>
        </div>
      </div>
      <ServiceUnavailableDialog
        :serviceUnavailableDialogOpen.sync="serviceUnavailableDialogOpen"
        imageResponse="KO-500"
        :title="titleServiceError"
        :body="bodyServiceError"
        cta1="Torna alla homepage"
        @goHomeOrGoForm="returnToHome"
      />
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { capitalize } from '@/js/utils'
import ServiceUnavailableDialog from '../global/ServiceUnavailableDialog.vue'
export default {
  name: 'SelfReadingModal',
  data() {
    return {
      filteredSupplies: {},
      loaded: false,
      titleServiceError: '',
      bodyServiceError: '',
      serviceUnavailableDialogOpen: false
    }
  },
  components: {
    ServiceUnavailableDialog
  },
  props: {
    selfReadingModalOpen: {
      type: Boolean,
      required: true
    }
  },
  async mounted() {
    this.filteredSupplies = await this.initFilteredSupplies()
    if (Object.keys(this.filteredSupplies).length > 0) {
      this.loaded = true
    } else {
      this.titleServiceError = 'Autolettura non necessaria'
      this.bodyServiceError =
        'Non ci sono indirizzi con consumi stimati che richiedono l’invio dell’autolettura.'
      this.serviceUnavailableDialogOpen = true
    }
  },
  computed: {
    ...mapGetters('supply', ['supplies']),
    ...mapGetters('account', ['accountClients']),
    dialogOpen: {
      get() {
        return this.selfReadingModalOpen
      },
      set(val) {
        this.$emit('update:selfReadingModalOpen', val)
      }
    }
  },
  methods: {
    ...mapActions('supply', ['getSupplies']),
    async initFilteredSupplies() {
      try {
        await this.getSupplies()
        const filteredSupplies = this.supplies.filter(
          (item) => item.isEstimate === true
        )
        const grouped = filteredSupplies.reduce((result, item) => {
          const { firstName, lastName, businessName } = item
          let fullName = capitalize(
            businessName ? businessName : `${firstName} ${lastName}`
          )
          if (!result[fullName]) {
            result[fullName] = []
          }
          result[fullName].push(item)
          return result
        }, {})
        return grouped
      } catch (error) {
        this.titleServiceError = 'Riprova più tardi'
        this.bodyServiceError =
          'Siamo spiacenti, ma per un errore temporaneo non è possibile visualizzare le tue forniture. Torna alla homepage per inviare l’autolettura.'
        this.serviceUnavailableDialogOpen = true
      }
    },
    pathImg(serviceType) {
      switch (serviceType) {
        case 'ELE':
          return require('../../assets/icons/ic-light-red.svg')
        case 'GAS':
          return require('../../assets/icons/ic-fire-red.svg')
      }
    },
    ctaTrigger(address) {
      this.$router.push({
        path: `supply/${address.clientOwnerCode}/${address.supplyCode}/selfreading/base`
      })
    },
    capitalizeAddress(address) {
      return capitalize(address)
    },
    returnToHome() {
      this.$router.push({ name: 'home' })
      this.$emit('update:selfReadingModalOpen', false)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../styles/variables.scss';
.selfReadingModal {
  background-color: white;
  padding: 40px;
  gap: 8px;
  .titleModal {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    img {
      cursor: pointer;
    }
  }
  .clients {
    .name {
      text-transform: capitalize;
      margin-bottom: 16px;
      margin-left: 16px;
      display: flex;
      align-items: center;
    }
    .res {
      &::before {
        content: url('../../assets/icons/ic-owner-res.svg');
        margin-right: 8px;
      }
    }
    .bus {
      &::before {
        content: url('../../assets/icons/business.svg');
        margin-right: 8px;
      }
    }
  }
  .supplies {
    display: flex;
    padding: 16px;
    border-radius: 20px;
    border: 1px solid $srg-purple;
    margin-top: 16px;
    cursor: pointer;
    .label {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .content {
        display: flex;
        align-items: center;
        .text {
          .address {
            text-transform: capitalize !important;
            margin-bottom: 4px;
          }
          .LabelM {
            color: $srg-purple;
          }
        }
      }
    }
  }
}
</style>
