<template>
  <div class="login" :class="{ 'in-modal': modalMode }">
    <TcVars />
    <div class="login__content">
      <img
        v-if="!modalMode"
        src="../assets/icons/my-sorgenia-logo.svg"
        width="203"
        height="56px"
      />
      <div class="login__box">
        <div v-if="modalMode" class="titleModal mb-12">
          <div class="HeadingsSubtitleL">Cambio password</div>
          <img
            src="@/assets/ic_chevron_top_black.png"
            alt="close"
            @click="closeDialog()"
          />
        </div>

        <div v-else class="login__title">Aggiorna password</div>
        <div
          v-if="!modalMode"
          class="d-flex align-center login__subTitle alert"
        >
          Aggiornare la password è un’indicazione normativa importante per la
          sicurezza dei tuoi dati
        </div>
        <div class="login__auth custom-input-field">
          <v-text-field
            v-if="modalMode"
            v-model.lazy="currentPassword"
            @blur="v$.currentPassword.$touch()"
            @input="resetErrors"
            :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showCurrentPassword ? 'text' : 'password'"
            class="auth__input"
            :error-messages="currentPasswordErrors"
            name="currentPassword"
            persistent-placeholder
            placeholder="Password corrente"
            label="PASSWORD CORRENTE*"
            @click:append="showCurrentPassword = !showCurrentPassword"
          >
            <template v-slot:append
              ><v-icon
                :class="{ 'show-password': showCurrentPassword }"
                @click="showCurrentPassword = !showCurrentPassword"
              ></v-icon
            ></template>
          </v-text-field>
          <v-text-field
            v-model.lazy="password"
            @blur="v$.password.$touch()"
            @input="inputNewPassword"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            name="password"
            :error-messages="newPasswordErrors"
            persistent-placeholder
            class="auth__input"
            placeholder="Nuova password"
            label="NUOVA PASSWORD*"
            @click:append="showPassword = !showPassword"
          >
            <template v-slot:append
              ><v-icon
                :class="{ 'show-password': showPassword }"
                @click="showPassword = !showPassword"
              ></v-icon
            ></template>
          </v-text-field>

          <v-text-field
            v-model.lazy="confirmPassword"
            @blur="v$.confirmPassword.$touch()"
            @input="resetErrors"
            :type="showConfirmPassword ? 'text' : 'password'"
            name="cofirmPassword"
            placeholder="Conferma password"
            label="CONFERMA PASSWORD*"
            persistent-placeholder
            :error-messages="confirmPasswordErrors"
            class="auth__input"
            @click:append="showConfirmPassword = !showConfirmPassword"
          >
            <template v-slot:append>
              <v-icon
                :class="{ 'show-password': showConfirmPassword }"
                @click="showConfirmPassword = !showConfirmPassword"
              ></v-icon>
            </template>
          </v-text-field>
        </div>
        <div class="login__error">
          <span class="red--text">{{ errorMessage }}</span>
        </div>
        <div class="auth__reg-password">
          <h5>La password deve avere:</h5>
          <div class="li">
            <span :class="formatPsw.eightChr ? 'ok' : 'ko'"></span>almeno 8
            caratteri
          </div>
          <div class="li">
            <span :class="formatPsw.lowercase ? 'ok' : 'ko'"></span>almeno 1
            carattere minuscolo
          </div>
          <div class="li">
            <span :class="formatPsw.uppercase ? 'ok' : 'ko'"></span>almeno 1
            carattere maiuscolo
          </div>
          <div class="li">
            <span :class="formatPsw.number ? 'ok' : 'ko'"></span>almeno 1 numero
          </div>
        </div>
        <div class="login__btn" :class="{ 'justify-center': modalMode }">
          <MYSButton
            button-class="primaryButtonXXL"
            :disabled="v$.$invalid"
            @buttonClick="changePassword"
            :button-text="modalMode ? 'Conferma' : 'Prosegui'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TcVars from '@/components/tcTracker/TcVars'
import GENERIC_ERRORS from '@/js/genericErrors'
import { minLength, required, sameAs, requiredIf } from '@vuelidate/validators'
import { mapActions } from 'vuex'
import MYSButton from '@/components/global/MYSButton.vue'

import { useVuelidate } from '@vuelidate/core'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'changePassword',
  props: {
    modalMode: { type: Boolean, require: false, default: false },
    bus: { type: Object }
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
      currentPassword: '',
      showPassword: false,
      showConfirmPassword: false,
      showCurrentPassword: false,
      errorMessage: '',
      errArray: [],
      formatPsw: {
        eightChr: false,
        lowercase: false,
        uppercase: false,
        oneChr: false
      },
      errorMessages: {
        WRONG_OLD_PASSWORD: 'La password non è corretta',
        PSW_CANNOT_BE_SAME:
          'La nuova password non può coincidere con la password corrente'
      },
      modalErrorMessage: {}
    }
  },
  computed: {
    confirmPasswordErrors: function () {
      let errArray = []

      if (!this.v$.confirmPassword.$dirty) return errArray

      this.v$.confirmPassword.sameAsPassword.$invalid === true &&
        errArray.push('Le password non coincidono')
      if (this.modalErrorMessage.confirmPasswordMessage) {
        errArray.push(this.modalErrorMessage.confirmPasswordMessage)
      }

      return errArray
    },
    currentPasswordErrors: function () {
      let errArray = []

      if (!this.v$.currentPassword.$dirty) return errArray
      if (this.modalErrorMessage.currentPasswordMessage) {
        errArray.push(this.modalErrorMessage.currentPasswordMessage)
      }
      return errArray
    },
    newPasswordErrors: function () {
      let errArray = []
      if (!this.v$.password.$dirty) return errArray

      this.v$.confirmPassword.sameAsPassword.$invalid === true &&
        this.v$.confirmPassword.$dirty &&
        errArray.push('Le password non coincidono')

      if (this.modalErrorMessage.newPasswordMessage) {
        errArray.push(this.modalErrorMessage.newPasswordMessage)
      }
      return errArray
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('session', ['changePasswordV3']),

    async changePassword() {
      this.errorMessage = ''

      // Show a loader
      if (this.modalMode) {
        try {
          this.trackTapEvent('account_passwordContinue_tap')
          await this.changePasswordV3({
            currentPassword: this.currentPassword,
            password: this.password
          })
          this.password = ''
          this.confirmPassword = ''
          this.currentPassword = ''
          this.showPassword = false
          this.showConfirmPassword = false
          this.showCurrentPassword = false
          this.v$.$reset()
          this.formatPsw = {
            eightChr: false,
            lowercase: false,
            uppercase: false,
            oneChr: false
          }
          this.$emit('closeModal')
        } catch (error) {
          switch (error.message) {
            case 'WRONG_OLD_PASSWORD':
              this.modalErrorMessage = {
                currentPasswordMessage: this.errorMessages[error.message]
              }
              break
            case 'PSW_CANNOT_BE_SAME':
              this.modalErrorMessage = {
                newPasswordMessage: this.errorMessages[error.message]
              }
              break
            default:
              this.errorMessage = GENERIC_ERRORS[error.message]
          }
        }
      } else {
        try {
          this.trackTapEvent('account_passwordContinue_tap')
          await this.$store.dispatch('session/changePassword', {
            password: this.password
          })
          this.$emit('closeModal')
        } catch (error) {
          console.error('Change pwd error' + error)
          this.errorMessage = GENERIC_ERRORS[error.message]
        }
      }
    },
    resetErrors() {
      this.modalErrorMessage = {}
    },
    handleReset() {
      this.confirmPassword = ''
      this.password = ''
      this.v$.$reset()
    },
    checkPassword() {
      this.formatPsw = {
        eightChr: this.password.length >= 8,
        lowercase: /[a-z]/.test(this.password),
        uppercase: /[A-Z]/.test(this.password),
        number: /[0-9]/.test(this.password)
      }
    },
    inputNewPassword() {
      this.checkPassword()
      this.resetErrors()
    },
    closeDialog() {
      this.password = ''
      this.confirmPassword = ''
      this.currentPassword = ''
      this.showPassword = false
      this.showConfirmPassword = false
      this.showCurrentPassword = false
      this.formatPsw = {
        eightChr: false,
        lowercase: false,
        uppercase: false,
        oneChr: false
      }
      this.v$.$reset()
      this.$emit('closeModalNoChange')
    }
  },
  created() {
    if (this.bus) {
      this.bus.$on('resetForm', this.handleReset)
    }
  },
  validations() {
    return {
      currentPassword: {
        requiredIf: requiredIf(this.modalMode)
      },
      password: {
        required,
        minLength: minLength(8),
        pattern: (value) => {
          // Pattern: Minimum eight characters, at least one uppercase letter, one lowercase letter and one number
          const patt = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/
          return patt.test(value)
        }
      },
      confirmPassword: {
        sameAsPassword: sameAs(this.password)
      }
    }
  },
  components: {
    TcVars,
    MYSButton
  }
}
</script>
<style lang="scss">
.topRadius .v-input--has-state.error--text .v-label {
  color: rgba(0, 0, 0, 0.87) !important;
}
.login .error--text .v-messages__message {
  &::before {
    margin-right: 6px;
  }
}
</style>
<style lang="scss" scoped>
@import '../styles/authentication/auth.scss';

.custom-input-field {
  input::placeholder {
    color: $neutri-neutral-40;
  }
  button {
    &::after {
      display: none;
    }
    &::before {
      content: '';
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 24px;
      height: 24px;
      top: 0;
      display: block;
      position: absolute;
      right: 0;
      background-image: url('../assets/hide_password.svg');
      opacity: 1;
    }
    &.show-password {
      &::before {
        background-image: url('../assets/show_password.svg');
      }
    }
  }
}
.auth__reg-password {
  margin-top: 10px;
  margin-bottom: 30px;

  h5 {
    font-size: 18px;
    letter-spacing: 1.3px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .li {
    display: flex;
    align-items: center;
    font-size: 16px;
    text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    margin-bottom: 10px;
    color: black;
    letter-spacing: 1px;
    .ok {
      background-image: url('../assets/ic_check_active_green.png');
      background-size: contain;
      width: 15px;
      height: 15px;
      margin-right: 10px;
    }
    .ko {
      background-image: url('../assets/ic_check_active_red_error.png');
      background-size: contain;
      width: 15px;
      height: 15px;
      margin-right: 10px;
    }
  }
}
.titleModal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    margin: 0 !important;
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
}
</style>
