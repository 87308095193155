<template>
  <div v-if="status === 'success' && detail" class="supply-detail topRadius">
    <!-- <TcVars
      env_template="mysorgenia_fornitura"
      :pdr="detail.pdr"
      :pod="detail.pod"
      :user_contract_code="cdPropostaContratto"
      :user_contract_code_fibra="cdPropostaContrattoFibra"
      v-if="detail && cdPropostaContratto && cdPropostaContrattoFibra"
    /> -->
    <TcVars />
    <v-dialog v-model="dialogPreview" max-width="750">
      <v-row>
        <v-col class="supply-dialog">
          <img
            @click="dialogPreview = false"
            src="../../assets/ic_chevron_top_black.png"
          />
          <h2>Migliora i tuoi consumi</h2>
          <h4 class="mb-7">
            Hai a disposizione il servizio di monitoraggio
            <strong>Beyond Energy</strong> con alcuni consigli per gestire al
            meglio i consumi della tua casa
          </h4>
          <h4 class="mb-7">
            Ecco un'<strong>anteprima</strong> di ciò che troverai:
          </h4>
          <img class="preview" src="./../../assets/energylab.png" />
        </v-col>
      </v-row>
    </v-dialog>
    <v-dialog v-model="dialogContact" max-width="750">
      <v-row>
        <v-col class="supply-dialog">
          <img
            @click="dialogContact = false"
            src="../../assets/ic_chevron_top_black.png"
          />
          <h2>Attiva il monitoraggio</h2>
          <h4>
            Chiama <strong>{{ formatPhoneNumber(landline) }}</strong> (da fisso)
            e <strong>{{ formatPhoneNumber(mobile) }}</strong> (da mobile) avrai
            l'assistenza di un nostro operatore per abilitare il servizio.
          </h4>
        </v-col>
      </v-row>
    </v-dialog>
    <v-dialog v-model="selfReadingDialog.show" max-width="750">
      <v-row>
        <v-col class="supply-dialog">
          <img
            @click="selfReadingDialog.show = false"
            src="../../assets/ic_chevron_top_black.png"
          />
          <h2 v-html="selfReadingDialog.title" />
          <h4 v-html="selfReadingDialog.text" />
        </v-col>
      </v-row>
    </v-dialog>
    <v-dialog v-model="noDataToExport" max-width="300">
      <v-row>
        <v-col class="supply-dialog small">
          <img
            @click="noDataToExport = false"
            src="../../assets/ic_chevron_top_black.png"
          />
          <h4>
            <strong
              >Non sono presenti consumi da esportare nell'intervallo
              selezionato</strong
            >
          </h4>
        </v-col>
      </v-row>
    </v-dialog>
    <v-dialog v-model="dialogPromoCode.show" max-width="750">
      <v-row>
        <v-col class="promo-dialog">
          <img
            class="close"
            @click="dialogPromoCode.show = false"
            src="../../assets/ic_chevron_top_black.png"
          />
          <div class="d-flex align-center justify-center mb-6">
            <img
              class="smile"
              :src="
                dialogPromoCode.result === 'OK'
                  ? require('../../assets/smile-active-green-1.svg')
                  : require('../../assets/ic-istant-win-ko-white.svg')
              "
            />
          </div>
          <h2 class="mb-4" v-if="dialogPromoCode.result === 'OK'">
            Codice inserito correttamente
          </h2>
          <h2 class="mb-4" v-else>
            Ci dispiace! Il servizio al momento non è disponibile
          </h2>
          <div class="desc mb-5" v-if="dialogPromoCode.result === 'OK'">
            L’importo del tuo codice promozionale è di
            <strong>{{ dialogPromoCode.importo }} €.</strong>
          </div>
          <div class="desc mb-8" v-else>
            Ti consigliamo di riprovare più tardi.
          </div>
          <div class="desc mb-8" v-if="dialogPromoCode.result === 'OK'">
            Lo sconto verrà applicato in bolletta e suddiviso in
            {{ dialogPromoCode.nRate }} rate per l’utenza luce di
            <span
              >{{ detail.address.toLowerCase() }},
              {{ detail.city.toLowerCase() }}</span
            >.
          </div>
          <div class="d-flex justify-center">
            <v-btn
              x-large
              class="white--text v-btn--shadowed"
              width="264"
              color="primary"
              :rounded="true"
              @click="$router.push({ name: 'home' })"
            >
              Torna alla homepage
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-dialog>
    <v-row v-if="!loaded" class="std-side-padding pa-12" align="center">
      <v-col class="text-center">
        <v-progress-circular :size="50" indeterminate />
      </v-col>
    </v-row>

    <template v-if="loaded">
      <v-row class="std-side-padding bkg--lightgray pt-9 pb-9">
        <v-col class="user">
          <UserDetail />
        </v-col>
      </v-row>
      <v-row
        v-if="
          detail.serviceType !== 'FIBER' &&
          detail.supplyState !== SUPPLY_DETAIL_STATUS.CLOSED &&
          detail.supplyState !== SUPPLY_DETAIL_STATUS.REJECTED
        "
      >
        <v-col class="col-12">
          <template
            v-if="detail.clientOwnerType === 'BUS' && !detail.isDigital"
          >
            <v-row no-gutters class="align-end mb-3 std-side-padding">
              <div class="d-flex flex-column chart-title">
                <h2><strong>Monitora i tuoi consumi</strong></h2>
                <h5>
                  <span
                    >Visualizza i consumi della tua fornitura
                    {{ luce2gas }} interagendo con il grafico sotto.</span
                  >
                </h5>
              </div>
            </v-row>
            <v-row class="std-side-padding">
              <v-tabs
                v-show="
                  detail.serviceType === 'Ele' && detail.billingType !== ''
                "
                grow
                v-model="tab"
                centered
              >
                <v-tab key="consumo">
                  <div class="customTab">Consumo</div>
                </v-tab>
                <v-tab
                  key="potenza"
                  v-if="
                    detail.serviceType === 'Ele' && detail.billingType !== ''
                  "
                >
                  <div class="customTab">Potenza impegnata</div>
                </v-tab>
              </v-tabs>
            </v-row>
            <!--            <v-row class="std-side-padding mt-5"-->
            <!--                   v-if="tab === 0 && curveConsumi && curveConsumi.curve && curveConsumi.curve.length > 0">Visualizza i-->
            <!--              tuoi consumi e confrontali con quelli di un periodo precedente.-->
            <!--            </v-row>-->
            <v-row
              no-gutters
              class="my-5 std-side-padding"
              v-if="checkActivationdDate"
            >
              <ChartUtils
                v-if="showChartUtils"
                :supplyType="detail.serviceType"
                :tabSelected="tab"
                :isFascia="isFascia"
                :billingType="detail.billingType"
                :activationDate="detail.activationDate"
                @getCurveConsumi="getCurveConsumi"
                :detail="detail"
                :consumptionVariation="consumptionVariation"
                ref="chartUtils"
              />
            </v-row>
            <div>
              <v-tabs-items v-model="tab">
                <v-tab-item key="consumo">
                  <template v-if="showChartLoader">
                    <v-progress-circular
                      class="chart-loader"
                      :size="134"
                      color="#12256A"
                      indeterminate
                    ></v-progress-circular>
                    <div class="textLoader">
                      Il caricamento dei dati potrebbe richiedere qualche
                      secondo
                    </div>
                  </template>
                  <template v-else>
                    <v-row v-if="curveConsumi && curveConsumi.curve.length > 0">
                      <ChartNew
                        type="line"
                        :series="curveConsumi"
                        :commodity="detail.serviceType"
                        :tabSelected="tab"
                        :seriesCompare="curveConsumiCompare"
                        :isFascia="isFascia"
                        :detail="detail"
                        :key="keyConsumo"
                      />
                    </v-row>
                    <v-row
                      v-else-if="!checkActivationdDate"
                      class="chart chart--empty std-side-padding"
                    >
                      <span
                        >Il grafico sarà popolato
                        <strong>dopo i primi mesi</strong> di consumo</span
                      >
                    </v-row>
                    <v-row v-else class="chart chart--empty std-side-padding">
                      <span
                        >Non sono disponibili consumi nell'intervallo
                        selezionato</span
                      >
                    </v-row>
                  </template>
                </v-tab-item>
                <v-tab-item key="potenza" v-if="detail.serviceType === 'Ele'">
                  <template v-if="showChartLoader">
                    <v-progress-circular
                      class="chart-loader"
                      :size="134"
                      color="#12256A"
                      indeterminate
                    ></v-progress-circular>
                    <div class="textLoader">
                      Il caricamento dei dati potrebbe richiedere qualche
                      secondo
                    </div>
                  </template>
                  <template v-else>
                    <v-row v-if="curvePotenza && curvePotenza.curve.length > 0">
                      <ChartNew
                        type="line"
                        :series="curvePotenza"
                        :commodity="detail.serviceType"
                        :tabSelected="tab"
                        :power="parseFloat(detail.power) || 3"
                        :isFascia="isFascia"
                        :detail="detail"
                        :key="keyPotenza"
                      />
                    </v-row>
                    <v-row
                      v-else-if="!checkActivationdDate"
                      class="chart chart--empty std-side-padding"
                    >
                      <span
                        >Il grafico sarà popolato
                        <strong>dopo i primi mesi</strong> di consumo</span
                      >
                    </v-row>
                    <v-row v-else class="chart chart--empty std-side-padding">
                      <span
                        >Non sono disponibili consumi nell'intervallo
                        selezionato</span
                      >
                    </v-row>
                  </template>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </template>

          <template v-else>
            <v-row no-gutters class="align-end mb-5 std-side-padding">
              <v-col class="chart col-9">
                <div class="d-flex flex-column chart-title mb-5">
                  <h2><strong>Monitora i tuoi consumi</strong></h2>
                  <h5
                    v-show="detail.consumption && detail.consumption.length > 0"
                  >
                    <span
                      >Visualizza i consumi della tua fornitura
                      {{ luce2gas }} interagendo con il grafico sotto.</span
                    >
                  </h5>
                </div>
              </v-col>
              <div
                v-if="showButtonChart"
                class="chart d-flex flex-row justify-space-between align-center chart-switch mt-0"
              >
                <div class="legenda">
                  <img
                    src="@/assets/icons/Circle-blu.png"
                    class="MediumCircle"
                  />
                  <span class="spese-anno-corrente">spese anno corrente</span>
                  <img
                    src="@/assets/icons/Circle-green.png"
                    class="MediumCircle"
                  />
                  <span class="spese-anno-corrente">spese anno precedente</span>
                </div>
                <div class="d-flex align-center">
                  <div class="chart-switch__label">
                    Confronta anno precedente
                  </div>
                  <v-switch
                    inset
                    color="#97D707"
                    v-model="monthOnMonth"
                  ></v-switch>
                </div>
              </div>
            </v-row>
            <v-row
              v-if="detail.consumption && detail.consumption.length > 0"
              class="std-side-padding"
            >
              <Chart
                type="line"
                :monthOnMonth="monthOnMonth"
                :series="detail.consumption"
                :commodity="detail.serviceType"
              />
            </v-row>
            <v-row v-else class="chart chart--empty">
              <span
                >Il grafico sarà popolato <strong>dopo i primi mesi</strong> di
                consumo</span
              >
            </v-row>
          </template>
        </v-col>
      </v-row>

      <v-row
        v-if="
          detail.serviceType === 'FIBER' &&
          detail.supplyState !== SUPPLY_DETAIL_STATUS.REJECTED
        "
        class="std-side-padding"
      >
        <v-col class="banner-fibra">
          <v-row no-gutters>
            <v-col class="col-6">
              <span>Traffico disponibile</span>
              <div class="banner-fibra__big">Illimitato</div>
            </v-col>
            <v-col class="col-6" v-if="currentSupply.speedVal">
              <span>Velocità fino a</span>
              <div class="banner-fibra__big">
                {{ $filters.formatSpeed(currentSupply.speedVal)
                }}<span> /sec</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="getDetailsData.infoPUN">
        <v-col>
          <PunCard :punCopies="punCardCopy" />
        </v-col>
      </v-row>
      <v-row
        class="std-side-padding mb-12 bidgely"
        v-if="
          detail.serviceType === 'Ele' &&
          detail.clientOwnerType === 'RES' &&
          detail.supplyState !== SUPPLY_DETAIL_STATUS.REJECTED
        "
      >
        <v-row>
          <v-col>
            <div class="mr-5">
              <div class="d-flex align-center">
                <h3 class="bills__title">Beyond Energy</h3>
                <img
                  class="selfReading_info ml-5"
                  src="../../assets/icons/info.svg"
                  width="32"
                  height="32"
                  @click="
                    ;(dialogPreview = true),
                      trackTapEvent({
                        name: 'supplyDetail_bidgelyInfo_tap',
                        params: getSupplyTrackingParams(detail)
                      })
                  "
                />
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-space-between align-center">
            <div
              v-if="bidgely.text"
              class="bills__desc"
              v-html="bidgely.text"
            />
            <div class="ml-5">
              <v-btn
                v-if="bidgely.action"
                class="v-btn--shadowed bidgely-btn"
                :rounded="true"
                @click="actionBidgely(bidgely.action)"
                large
                width="310"
                color="white"
              >
                {{ bidgely.cta }}
              </v-btn>
            </div>
            <!-- <v-row :class="`${bidgely.action === 'supplyBidgely' ? 'align-start' : 'align-end'} d-flex justify-space-between`">
              <div class="d-flex flex-column">
                <div class="d-flex mb-7">
                  <h3 class="bills__title">I tuoi consumi in dettaglio</h3>
                  <img class="selfReading_info ml-5"
                       src="../../assets/icons/info.svg"
                       width="32" height="32"
                       @click="dialogPreview = true;trackTapEvent({ name: 'supplyDetail_bidgelyInfo_tap', params: getSupplyTrackingParams(detail)})"
                  />
                </div>
              </div>
            </v-row>
            <v-row class="d-flex justify-space-between">
              <div class="bills__desc" v-html="bidgely.text"/>
              <v-btn
                v-if="bidgely.action"
                class="v-btn--shadowed bidgely-btn"
                :rounded="true"
                @click="actionBidgely(bidgely.action)"
                large
                width="310"
                color="white"
              >
                {{ bidgely.cta }}
              </v-btn>
            </v-row> -->
          </v-col>
        </v-row>
      </v-row>

      <v-row
        class="std-side-padding bkg--lightgray pb-9"
        v-if="detail.supplyState !== SUPPLY_DETAIL_STATUS.REJECTED"
      >
        <v-col class="bills">
          <v-row class="align-center">
            <v-col class="col-md-5 py-0">
              <h2 class="bills__title mb-0">Bollette e piani di rientro</h2>
            </v-col>
          </v-row>
          <v-row class="end mb-4">
            <v-col
              cols="4"
              class="py-0 d-flex flex-column justify-center"
              v-if="
                detail.supplyState !== SUPPLY_DETAIL_STATUS.ACTIVATING &&
                detail.supplyState !== SUPPLY_DETAIL_STATUS.REJECTED
              "
            >
              <h4
                v-if="
                  hasPaymentInfo &&
                  currentSupply.hasRegularStatus &&
                  rateScadute === 0
                "
                class="color--blue"
              >
                <strong>{{ labelRegularPayment }}</strong>
              </h4>
              <h4
                v-else-if="hasPaymentInfo && !currentSupply.hasRegularStatus"
                class="color--purple"
              >
                <strong>{{ paymentStatus }}</strong>
              </h4>
              <h4 class="color--purple" v-if="rateScadute > 0">
                <strong v-if="rateScadute === 1"
                  >{{ rateScadute }} RATA SCADUTA</strong
                >
                <strong v-else>{{ rateScadute }} RATE SCADUTE </strong>
              </h4>
            </v-col>

            <v-col cols="8" class="d-flex justify-end py-0">
              <div
                class="bills__archive ml-5"
                v-if="
                  currentSupply.hasCredit &&
                  detail.supplyState === SUPPLY_DETAIL_STATUS.ACTIVE
                "
              >
                <v-btn
                  color="white"
                  class="bills__btn larger-btn"
                  :rounded="true"
                  @click="requestCredit()"
                >
                  Hai un credito: richiedilo
                </v-btn>
              </div>
              <div
                class="bills__archive ml-5"
                v-if="(hasPaymentInfo && hasInregularPayment) || rateScadute"
              >
                <v-btn
                  class="bills__btn expiredBills"
                  color="white"
                  :rounded="true"
                  @click="goToPagamenti(detail)"
                >
                  Vedi pagamenti scaduti
                  <span>{{
                    currentSupply.notRegularPayments + rateScadute
                  }}</span>
                </v-btn>
              </div>
              <div
                :class="`bills__archive ml-5 ${
                  detail.bills &&
                  detail.bills.length === 0 &&
                  getInfoPiani.item.length == 0
                    ? 'd-none'
                    : ''
                }`"
              >
                <v-btn
                  class="white--text bills__btn px-10"
                  color="primary"
                  :rounded="true"
                  @click="goToRiepilogo(detail)"
                >
                  Riepilogo e archivio pagamenti
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <!-- <v-row v-if="filteredAll.length === 0" class="mt-10">
            <v-col class="bills__list">
              <v-row align="center">
                <v-col class="empty_text std-panel std-panel--empty">
                  <div
                    class="text-center pt-5 pb-5"
                    v-html="billingFrequency"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col class="bills__list">
              <ListPdrBills
                :filteredAll="filteredAll"
                :detail="detail"
                :loadBills="loadBills"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <template
        v-if="
          detail.selfReading !== null &&
          detail.supplyState !== SUPPLY_DETAIL_STATUS.CLOSED &&
          detail.supplyState !== SUPPLY_DETAIL_STATUS.REJECTED
        "
      >
        <v-row
          class="std-side-padding unavailable"
          v-if="
            selfReadingData.status === 'TOO_EARLY' ||
            selfReadingData.status === 'NOT_NEEDED' ||
            selfReadingData.status === 'NOT_AVAILABLE'
          "
        >
          <v-col class="selfReading px-3">
            <div class="d-flex align-center">
              <h2 class="selfReading__title mr-5">Lettura</h2>
              <img
                v-if="
                  detail.selfReading.ultimalettura &&
                  detail.selfReading.ultimalettura.dataLettura &&
                  checkActivationdDate
                "
                class="mb-2 cursor-pointer"
                src="@/assets/icons/info.svg"
                @click="openDialogHelp('help-meter-reading')"
              />
            </div>
            <div class="d-flex justify-space-between align-center">
              <h3
                v-if="
                  detail.selfReading.ultimalettura &&
                  detail.selfReading.ultimalettura.dataLettura &&
                  checkActivationdDate
                "
              >
                <span class="selfReading_dateReading">Ultima lettura:</span>
                {{
                  $filters.formatDateWithSeparator(
                    detail.selfReading.ultimalettura.dataLettura,
                    '.'
                  )
                }}
              </h3>
              <div class="d-flex align-center">
                <h5 class="mr-4" v-html="selfReadingData.text" />
                <img
                  class="selfReading_info"
                  v-if="selfReadingData.info_popup"
                  src="../../assets/icons/info.svg"
                  @click="dataAlert(selfReadingData.info_popup)"
                />
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row
          class="std-side-padding available"
          v-else-if="
            selfReadingData.status === 'THROUGH_CALLCENTER' ||
            selfReadingData.status === 'ALLOWED'
          "
        >
          <v-col class="selfReading px-3">
            <div class="d-flex align-center">
              <h2 class="selfReading__title mr-5">Lettura</h2>
              <img
                class="mb-2"
                src="../../assets/ic-info-white.svg"
                @click="openDialogHelp('help-meter-reading')"
              />
            </div>
            <div class="d-flex justify-space-between align-end">
              <div>
                <h3
                  v-if="
                    detail.selfReading.ultimalettura &&
                    detail.selfReading.ultimalettura.dataLettura &&
                    validDateSupply(
                      detail.selfReading.ultimalettura.dataLettura
                    )
                  "
                >
                  Ultima lettura:
                  {{
                    $filters.formatDateWithSeparator(
                      detail.selfReading.ultimalettura.dataLettura,
                      '.'
                    )
                  }}
                </h3>
                <h5
                  v-if="selfReadingData.text"
                  class="mt-4"
                  v-html="selfReadingData.text"
                />
              </div>
              <div>
                <v-btn
                  class="v-btn--shadowed btnSelfReading"
                  :rounded="true"
                  @click="
                    selfReadingData.status === 'THROUGH_CALLCENTER'
                      ? dataAlert(selfReadingData.cta_action)
                      : $router.push({ name: 'selfReadingLight_Base' }),
                      trackTapEvent({
                        name: 'supplyDetail_selfReading_tap',
                        params: getSupplyTrackingParams(detail)
                      })
                  "
                  large
                  width="263"
                  height="56"
                  color="white"
                >
                  {{ selfReadingData.cta }}
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </template>

      <v-row
        v-if="
          detail.supplyState !== SUPPLY_DETAIL_STATUS.CLOSED &&
          detail.supplyState !== SUPPLY_DETAIL_STATUS.REJECTED
        "
        class="std-side-padding mt-3"
      >
        <v-col class="actions pb-12">
          <v-row class="align-center mb-4">
            <v-col class="col-md-6">
              <h2 class="action__title">Gestisci utenza</h2>
              <!-- <h5  v-if="detail.serviceType !== 'FIBER'" class="action__subtitle">Visualizza le informazioni principali della tue fornitura</h5> -->
            </v-col>
            <!-- <v-col class="col-md-6 action__archive">
              <v-btn
                v-if="detail.serviceType === 'FIBER'"
                class="white--text bills__btn"
                color="#12256A"
                :rounded="true"
              >
                Archivio azioni
              </v-btn>
            </v-col> -->
          </v-row>
          <div v-if="detail.serviceType === 'Gas'">
            <carousel
              :perPage="this.lenghtActions()"
              :navigationEnabled="true"
              :navigation-next-label="nextLabel"
              :navigation-prev-label="prevLabel"
              paginationColor="#CFCFCF"
              paginationActiveColor="#12256A"
              :paginationPadding="5"
            >
              <slide v-for="item in actions" :key="item.id">
                <v-col align="center" class="action-slide">
                  <div
                    class="action-panel"
                    @click="
                      item.name === 'Parental Control'
                        ? $router.push({ name: item.route })
                        : $router.push({ name: item.route }),
                        trackTapEvent({
                          name: item.trackingEventName,
                          params: getSupplyTrackingParams(detail)
                        })
                    "
                  >
                    <img
                      class="action__img"
                      :src="require('@/assets/supplies/' + item.filename)"
                    />
                    <div class="action__type color--blue">
                      <h5>{{ item.name }}</h5>
                    </div>
                  </div>
                </v-col>
              </slide>
            </carousel>
            `
          </div>
          <v-row v-else>
            <v-col
              class="action__btn col"
              v-for="item in actions"
              :key="item.id"
            >
              <div
                class="action-panel"
                @click="
                  item.name === 'Parental Control'
                    ? $router.push({
                        name: item.route,
                        params: item.params || {}
                      })
                    : ($router.push({
                        name: item.route,
                        params: item.params || {}
                      }),
                      trackTapEvent({
                        name: item.trackingEventName,
                        params: getSupplyTrackingParams(detail)
                      }))
                "
              >
                <img
                  class="action__img"
                  :src="require('@/assets/supplies/' + item.filename)"
                />
                <div class="action__type color--blue">
                  <h5>{{ item.name }}</h5>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row
        class="std-side-padding rewards"
        v-if="
          detail.serviceType !== 'FIBER' &&
          detail.supplyState === SUPPLY_DETAIL_STATUS.ACTIVE
        "
      >
        <v-col class="px-3 pb-0">
          <h2 class="mb-4"><strong>Premi per te</strong></h2>
          <div class="d-flex align-center justify-space-between mb-4">
            <div class="desc mb-3">
              Hai ricevuto un codice promozionale e vuoi attivare lo sconto in
              bolletta?
            </div>
            <!-- <h5 class="color--blue"><strong>Visualizza lo storico dei tuoi codici</strong></h5> -->
          </div>
          <div class="d-flex align-center">
            <div class="inputCode mr-5">
              <v-text-field
                v-model="promoCode"
                placeholder="Inserisci il codice promozionale"
                persistent-placeholder
                @input="v$.promoCode.$touch()"
                :error-messages="verifyCodeErrors"
              />
            </div>
            <v-btn
              x-large
              class="v-btn--shadowed"
              width="264"
              color="primary"
              :rounded="true"
              :disabled="v$.promoCode.$invalid"
              @click="sendCode"
            >
              Conferma
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row
        v-if="detail.supplyState !== SUPPLY_DETAIL_STATUS.REJECTED"
        class="std-side-padding bkg--lightgray pt-3 pb-7"
      >
        <v-col class="supply-info">
          <v-row class="align-center" no-gutters>
            <v-col>
              <h2 class="info__title">Informazioni</h2>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="info__list">
              <v-row align="center">
                <v-col>
                  <v-row no-gutters class="info__item">
                    <v-col class="col-md-3">
                      <h6>Intestatario</h6>
                      <h5>
                        {{
                          detail.clientOwnerType === 'RES'
                            ? detail.firstName + ' ' + detail.lastName
                            : detail.businessName
                        }}
                      </h5>
                    </v-col>
                    <v-col class="col-md-3">
                      <h6>Tipologia cliente</h6>
                      <h5>
                        {{
                          detail.clientOwnerType === 'RES'
                            ? 'Residenziale'
                            : 'Business'
                        }}
                      </h5>
                    </v-col>
                    <v-col v-if="detail.serviceType === 'Ele'" class="col-md-3">
                      <h6>POD</h6>
                      <h5>{{ detail.pod }}</h5>
                    </v-col>
                    <v-col v-if="detail.serviceType === 'Gas'" class="col-md-3">
                      <h6>PDR</h6>
                      <h5>{{ detail.pdr }}</h5>
                    </v-col>
                    <v-col class="col-md-3">
                      <h6>Metodo di pagamento</h6>
                      <h5 v-if="detail.mdp && detail.mdp.length > 0">
                        {{ paymentLabel(detail.mdp[0].mdP) }}
                        {{ detail.mdp[0].iban }}
                      </h5>
                      <h5 v-else>-</h5>
                    </v-col>
                    <v-col
                      v-if="detail.serviceType === 'FIBER'"
                      class="col-md-3"
                    >
                      <h6>Offerta</h6>
                      <h5
                        class="color--blue"
                        @click="
                          trackTapEvent('supplyDetail_productName_tap'),
                            $router.push({
                              name: 'account',
                              params: {
                                showContract: $route.params.supplyCode,
                                clientOwnerCode: $route.params.clientOwnerCode
                              }
                            })
                        "
                      >
                        {{ getProdutcs(detail.product) }}
                      </h5>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col>
                  <v-row no-gutters class="info__item">
                    <v-col v-if="detail.serviceType === 'Ele'" class="col-md-3">
                      <h6>Potenza</h6>
                      <h5>{{ powerFormatted }}kW</h5>
                    </v-col>
                    <v-col
                      v-if="
                        detail.serviceType === 'Ele' ||
                        detail.serviceType === 'Gas'
                      "
                      class="col-md-3"
                    >
                      <h6>Offerta</h6>
                      <h5
                        class="color--blue"
                        @click="
                          trackTapEvent('supplyDetail_productName_tap'),
                            $router.push({
                              name: 'account',
                              params: {
                                showContract: $route.params.supplyCode,
                                clientOwnerCode: $route.params.clientOwnerCode
                              }
                            })
                        "
                      >
                        {{ getProdutcs(detail.product) }}
                      </h5>
                    </v-col>
                    <v-col v-if="detail.migrationCode" class="col-md-3">
                      <h6>Codice di migrazione</h6>
                      <h5>{{ detail.migrationCode }}</h5>
                    </v-col>
                    <v-col class="col-md-3">
                      <h6>Data di attivazione</h6>
                      <h5>
                        {{
                          $filters.formatDateWithSeparator(
                            detail.activationDate,
                            '.'
                          )
                        }}
                      </h5>
                    </v-col>
                    <v-col
                      v-if="detail.serviceType !== 'FIBER'"
                      class="col-md-3"
                    >
                      <h6>Benvenuto in Sorgenia</h6>
                      <h5>
                        <a
                          :href="
                            detail.cadastral ? detail.cadastral.pdfUrl : '#'
                          "
                          target="_blank"
                          >lettera di benvenuto</a
                        >
                      </h5>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row
        v-if="detail.supplyState === SUPPLY_DETAIL_STATUS.REJECTED"
        class="std-side-padding supply-rejected"
      >
        <div class="supply-rejected__text">
          <h5 class="mb-4">
            Ci dispiace ma non è stato possibile attivare la tua utenza. Non si
            è infatti verificata una o più delle condizioni esposte all’art. 5
            del contratto che hai sottoscritto.
          </h5>
          <h5
            class="color--blue help"
            @click="openDialogHelp('help-refused-supply')"
          >
            Perchè l’utenza è stata annullata?
          </h5>
        </div>
        <v-btn
          @click="$router.push({ name: 'home' })"
          height="54"
          color="primary"
          width="290"
          class="v-btn--shadowed mb-8 mx-auto"
          >Torna alla Homepage
        </v-btn>
      </v-row>
    </template>
    <DialogContextualHelp
      v-model="dialogHelp"
      :clientCode="$route.params.clientOwnerCode"
      :idContextualHelp="contextualHelpId"
    />
  </div>
  <div v-else-if="status === 'error'">Error</div>
  <div v-else>
    <!-- <h1>Loading </h1> -->
  </div>
</template>

<script>
import DialogContextualHelp from '@/components/contextualHelp/Dialog'
import TcVars from '@/components/tcTracker/TcVars'
import { paymentTypeLabel } from '@/js/constants'
import GenErr from '@/js/genericErrors'
import CheckBidgelyVisibility from '@/js/service/checkBidgelyVisibility'
import { getSupplyTrackingParams } from '@/js/trackingUtils'
import { validDate, validDateSupply } from '@/js/utils'
import { dynamicSort, hasCharacterValid } from '@/js/utils.js'
import { required } from '@vuelidate/validators'
import { ExportToCsv, generateCsv, download } from 'export-to-csv'
import { Carousel, Slide } from 'vue-carousel'
import { mapActions, mapGetters } from 'vuex'
import ListPdrBills from './ListPdrBills.vue'
import Chart from '../../components/chart/Chart'
import ChartNew from '../../components/chart/ChartNew'
import ChartUtils from '../../components/chart/ChartUtils'
import UserDetail from '../../components/user/UserDetail'
import constants from '../../js/constants'
import router from '../../router/router'
import PunCard from '../../components/pun/PunCard.vue'

import { useVuelidate } from '@vuelidate/core'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'SupplyDetail',
  data() {
    return {
      bidgely: {
        action: '--',
        cta: '',
        text: ''
      },
      SUPPLY_DETAIL_STATUS: constants.SUPPLY_DETAIL_STATUS,
      keyConsumo: 0,
      keyPotenza: 0,
      tab: 0,
      curveConsumi: null,
      curveConsumiCompare: null,
      curvePotenza: null,
      isFascia: false,
      showChartLoader: false,
      noDataToExport: false,
      //detail: {},
      clientDetail: {},
      luce2gas: '',
      loaded: false,
      loadBills: false,
      nextLabel: `<img src='${require('../../assets/ic_chevron_dx_white.png')}' />`,
      prevLabel: `<img src='${require('../../assets/ic_chevron_sx_white.png')}' />`,
      fiberActions: [
        {
          name: 'Parental Control',
          filename: 'parental-control.svg',
          route: 'parentalControlAction'
        },
        {
          name: 'Assistenza per malfunzionamento linea',
          filename: 'malfunzionamento-fibra.svg',
          route: 'fiberAction',
          params: { actionType: 'lineIssues' },
          trackingEventName: 'supplyDetail_lineIssues_tap'
        },
        {
          name: 'Assistenza su modem',
          filename: 'malfunzionamento-modem.svg',
          route: 'fiberAction',
          params: { actionType: 'modemIssues' },
          trackingEventName: 'supplyDetail_modemIssues_tap'
        }
      ],
      lightActions: [
        {
          name: 'Cambio metodo pagamento',
          filename: 'pagamento.svg',
          route: 'paymentAction',
          trackingEventName: 'supplyDetail_mdpAction_tap'
        },
        {
          name: 'Variazione potenza',
          filename: 'potenza.svg',
          route: 'powerAction',
          trackingEventName: 'supplyDetail_powerAction_tap'
        },
        {
          name: 'Gestione contatore',
          filename: 'contatore.svg',
          route: 'counterAction',
          trackingEventName: 'supplyDetail_counterAction_tap'
        },
        {
          name: 'Gestione tensione',
          filename: 'tensione.svg',
          route: 'voltageAction',
          trackingEventName: 'supplyDetail_voltageAction_tap'
        }
      ],
      gasActions: [
        {
          name: 'Cambia metodo di pagamento',
          filename: 'pagamento.svg',
          route: 'paymentAction',
          trackingEventName: 'supplyDetail_sendCadastral_tap'
        }
      ],
      monthOnMonth: true,
      callcenterMobile: '',
      dialogPreview: false,
      dialogContact: false,
      validDateSupply: validDateSupply,
      selfReadingDialog: {
        show: false,
        title: '',
        text: ''
      },
      landline: '',
      mobile: '',
      getSupplyTrackingParams: getSupplyTrackingParams,
      cdPropostaContratto: null,
      cdPropostaContrattoFibra: null,
      mainClient: null,
      dialogHelp: false,
      contextualHelpId: '',
      consumptionVariation: null,
      promoCode: '',
      errorPromoCode: null,
      dialogPromoCode: {
        show: false,
        result: '',
        importo: '',
        nRate: ''
      },
      rateScadute: 0,
      bidgelyStatus: ''
    }
  },
  watch: {
    tab(val) {
      val === 0 ? this.keyConsumo++ : this.keyPotenza++
      if (
        this.curveConsumiCompare &&
        this.curveConsumiCompare.frazione === '4' &&
        val === 1
      ) {
        this.$refs.chartUtils.removeDateSelected('to')
      }
      if (this.curveConsumi.frazione === '4' && val === 1) {
        this.$refs.chartUtils.periodSelected = 'monthly'
        this.$refs.chartUtils.removeDateSelected('from')
      }
      if (val === 0) {
        this.trackTapEvent({
          name: 'supplyDetail_chartConsHeader_tap',
          params: getSupplyTrackingParams(this.detail)
        })
      } else {
        this.trackTapEvent({
          name: 'supplyDetail_chartPowerHeader_tap',
          params: getSupplyTrackingParams(this.detail)
        })
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.loadBills = true
    }, 3000)
    this.getDetail({
      clientOwnerCode: this.$route.params.clientOwnerCode,
      supplyCode: this.$route.params.supplyCode
    })
    this.retrieveParentalControlStatus({
      clientOwnerCode: this.$route.params.clientOwnerCode,
      supplyCode: this.$route.params.supplyCode
    })
  },
  created() {
    this.init()
  },
  computed: {
    ...mapGetters('account', ['accountClients', 'accountClients']),
    ...mapGetters('supply', [
      'supplies',
      'supplyDetail',
      'getParentalControlStatus',
      'getDetailsData'
    ]),
    ...mapGetters('payment', ['getInfoPiani']),
    detail() {
      return this.supplyDetail &&
        this.supplyDetail[this.$route.params.supplyCode]
        ? this.supplyDetail[this.$route.params.supplyCode]
        : []
    },
    punCardCopy() {
      return this.getDetailsData.infoPUN
    },
    sortedBills() {
      //filtro ultimi 2 anni bollette
      let endDate = new Date()
      let startDate = new Date().setMonth(endDate.getMonth() - 24)
      let filteredBills = this.detail.bills.filter((bill) => {
        let dataEmiss = new Date(
          bill.dataInizioCompetenza.split('/').reverse().join('-')
        )
        bill.sortableDate = dataEmiss
        //this.filteredAll.push(bill)

        return dataEmiss >= startDate && dataEmiss <= endDate
      })
      return filteredBills
    },

    sortedPdr() {
      //filtro ultimi 2 anni pdr
      let endDate = new Date()
      let startDate = new Date().setMonth(endDate.getMonth() - 24)
      let filteredPdr = this.pdrToShow.filter((pdr) => {
        let dataEmiss = new Date(
          pdr.planStartDate.split('/').reverse().join('-')
        )
        pdr.sortableDate = dataEmiss
        //this.filteredAll.push(pdr)

        return dataEmiss >= startDate && dataEmiss <= endDate
      })
      return filteredPdr
    },
    filteredAll() {
      let filteredSorted = this.sortedBills.concat(this.sortedPdr)
      return filteredSorted.sort(dynamicSort('-sortableDate'))
    },
    pdrToShow() {
      return this.getInfoPiani.item.filter(
        (pdr) => pdr.planStatus === constants.STATUS_PDR.ACTIVE
      )
    },
    verifyCodeErrors() {
      let errArray = []

      if (!this.v$.promoCode.$dirty) return errArray

      this.v$.promoCode.required.$invalid === true &&
        errArray.push('Il codice promozionale è obbligatorio')
      this.v$.promoCode.hasCharacterValid === false &&
        errArray.push('Non sono ammessi caratteri speciali')
      if (this.errorPromoCode) {
        if (this.errorPromoCode.errorCode === '001') {
          errArray.push('Il codice è già stato utilizzato')
        } else if (this.errorPromoCode.errorCode === '002') {
          errArray.push('Il codice non è più valido')
        } else if (this.errorPromoCode.errorCode === '003') {
          errArray.push(
            'Al momento il servizio non è disponibile. Riprova più tardi'
          )
        }
      }

      return errArray
    },

    hasPaymentInfo() {
      return this.currentSupply && this.currentSupply.hasPaymentInfo
    },
    hasInregularPayment() {
      return (
        this.currentSupply &&
        this.currentSupply.paymentStatus === 'Non regolari'
      )
    },
    labelRegularPayment() {
      if (this.getInfoPiani?.item.length > 0) {
        return 'PAGAMENTI REGOLARI'
      } else {
        return 'BOLLETTE REGOLARI'
      }
    },
    paymentStatus() {
      if (this.currentSupply.notRegularPayments > 1) {
        return this.currentSupply.notRegularPayments + ' BOLLETTE SCADUTE'
      } else {
        return this.currentSupply.notRegularPayments + ' BOLLETTA SCADUTA'
      }
    },
    belongClient() {
      return Object.keys(this.accountClients).includes(
        this.$route.params.clientOwnerCode
      )
    },

    currentSupply() {
      return this.supplies
        ? this.supplies.filter(
            (item) => item.supplyCode === this.$route.params.supplyCode
          )[0]
        : null
    },
    status() {
      return this.$store.state.supply.status
    },
    showButtonChart() {
      return (
        this.detail &&
        this.detail.consumption &&
        this.detail.consumption.length >= 12
      )
    },
    powerFormatted() {
      if (this.detail.power) {
        return this.detail.power.indexOf('.') > -1
          ? this.detail.power.substr(0, this.detail.power.indexOf('.') + 3)
          : this.detail.power
      }
      return '-'
    },
    actions() {
      let res = []
      switch (this.detail.serviceType) {
        case 'Ele':
          res = this.lightActions
          break
        case 'FIBER':
          res = this.fiberActions
          break
        case 'Gas':
          res = this.gasActions
          break
      }
      return res
    },
    checkActivationdDate() {
      if (validDate(this.detail.activationDate)) {
        let date = new Date(this.detail.activationDate)
        date = date.setMonth(date.getMonth() + 1)
        return new Date().getTime() > date
      }
      return false
    },
    selfReadingData() {
      let status = this.detail.selfReading.availabilityStatus
      if (status === 'NOT_AVAILABLE') {
        if (this.checkActivationdDate) {
          return {
            status: 'NOT_AVAILABLE',
            text: 'Autolettura non disponibile',
            info_popup: '',
            cta: '',
            cta_action: ''
          }
        } else {
          return {
            status: 'TOO_EARLY',
            text: 'Qui potrai comunicare le tue letture <strong>dopo il primo mese</strong> di attivazione',
            info_popup:
              "Nel primo mese di fornitura puoi comunicarci l'autolettura telefonicamente contattando il nostro Servizio Clienti al numero <strong>" +
              this.formatPhoneNumber(this.landline) +
              '</strong> (da fisso) oppure <strong>' +
              this.formatPhoneNumber(this.mobile) +
              '</strong> (da mobile). Poi potrai farlo direttamente da qui!',
            cta: '',
            cta_action: ''
          }
        }
      } else if (status === 'ALLOWED') {
        if (this.checkActivationdDate) {
          return {
            status: 'ALLOWED',
            text: "Ti consigliamo di inviarci l'autolettura entro il 5° giorno del mese.",
            info_popup: '',
            cta: 'Invia autolettura',
            cta_action: ''
          }
        } else {
          return {
            status: 'TOO_EARLY',
            text: 'Qui potrai comunicare le tue letture <strong>dopo il primo mese</strong> di attivazione',
            info_popup:
              "Nel primo mese di fornitura puoi comunicarci l'autolettura telefonicamente contattando il nostro Servizio Clienti al numero <strong>" +
              this.formatPhoneNumber(this.landline) +
              '</strong> (da fisso) oppure <strong>' +
              this.formatPhoneNumber(this.mobile) +
              '</strong> (da mobile). Poi potrai farlo direttamente da qui!',
            cta: '',
            cta_action: ''
          }
        }
      } else if (status === 'NOT_NEEDED') {
        return {
          status: 'NOT_NEEDED',
          text: 'Autolettura non necessaria. Consumi rilevati <strong>automaticamente</strong>.',
          info_popup:
            this.detail.serviceType === 'Gas'
              ? 'Per questa fornitura i consumi vengono rilevati automaticamente. Se vuoi comunque avere ulteriori informazioni sulla gestione della lettura chiamaci al numero  <strong>' +
                this.formatPhoneNumber(this.landline) +
                '</strong> (da fisso) oppure al <strong>' +
                this.formatPhoneNumber(this.mobile) +
                '</strong> (da mobile)'
              : '',
          cta: '',
          cta_action: ''
        }
      } else if (status === 'THROUGH_CALLCENTER') {
        return {
          status: 'THROUGH_CALLCENTER',
          text: "Ti consigliamo di inviarci l'autolettura entro il 5° giorno del mese.",
          info_popup: '',
          cta: 'Comunica autolettura',
          cta_action:
            'Per questa fornitura puoi comunicarci la lettura contattando il nostro Servizio Clienti al numero <strong>' +
            this.formatPhoneNumber(this.landline) +
            '</strong> (da fisso) oppure <strong>' +
            this.formatPhoneNumber(this.mobile) +
            '</strong> (da mobile).'
        }
      } else {
        return false
      }
    },
    CheckSupplyPermission() {
      return new CheckBidgelyVisibility(this.clientDetail, this.currentSupply)
    },
    showChartUtils() {
      if (this.detail.billingType === '') {
        return this.detail.serviceType === 'Gas'
      }
      return true
    }
  },
  methods: {
    async init() {
      let main = await this.getMainClient()
      this.mainClient = await this.getDetailForClient({
        clientOwnerCode: main.clientOwnerCode
      })
      if (Object.keys(this.accountClients).length === 1) {
        this.mainClient.mainRole = '1'
      }
      this.clientDetail = await this.getDetailForClient({
        clientOwnerCode: this.$route.params.clientOwnerCode
      })

      await this.getPdrInfo({
        clientCode: this.$route.params.clientOwnerCode,
        prCode: this.$route.params.supplyCode
      })
      await this.getSupplies()

      this.luce2gas =
        this.detail.serviceType.toLowerCase() === 'ele'
          ? 'luce'
          : this.detail.serviceType.toLowerCase() === 'gas'
          ? 'gas'
          : ''

      let digitalContracts = await this.getContractsForClient({
        clientOwnerCode: this.$route.params.clientOwnerCode
      })

      let noDigitalContracts = await this.getNotDigitalContracts({
        clientOwnerCode: this.$route.params.clientOwnerCode
      })

      digitalContracts.contractDocumentsList.forEach((contr) => {
        if (
          contr.cdPunto === this.detail.supplyCode &&
          contr.cdPropostaContratto
        ) {
          this.cdPropostaContratto = contr.cdPropostaContratto
        }
        if (
          contr.cdPunto === this.detail.supplyCode &&
          contr.cdPropostaContrattoFibra
        ) {
          this.cdPropostaContrattoFibra = contr.cdPropostaContrattoFibra
        }
      })
      noDigitalContracts.contractDocumentsList.forEach((contr) => {
        if (
          contr.cdPunto === this.detail.supplyCode &&
          contr.cdPropostaContratto
        ) {
          this.cdPropostaContratto = contr.cdPropostaContratto
        }
        if (
          contr.cdPunto === this.detail.supplyCode &&
          contr.cdPropostaContrattoFibra
        ) {
          this.cdPropostaContrattoFibra = contr.cdPropostaContrattoFibra
        }
      })

      this.cdPropostaContratto = this.cdPropostaContratto || '-'
      this.cdPropostaContrattoFibra = this.cdPropostaContrattoFibra || '-'

      let _minDate = new Date(
        new Date().setFullYear(new Date().getFullYear() - 2)
      )
      let _activationDate = new Date(this.detail.activationDate)
      if (_activationDate && _activationDate > _minDate) {
        _minDate = _activationDate
      }
      this.countTotalScaduteInstalments()
      this.$store
        .dispatch('supply/getCurveConsumi', {
          clientOwnerCode: this.$route.params.clientOwnerCode,
          supplyCode: this.$route.params.supplyCode,
          endDate: new Intl.DateTimeFormat('it', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          }).format(new Date()),
          startDate: new Intl.DateTimeFormat('it', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          }).format(_minDate),
          frazione: '1'
        })
        .then((curve) => {
          this.curveConsumi = curve
          this.curvePotenza = curve
          if (
            curve.curve.length > 0 &&
            Object.keys(curve.curve[0]).findIndex((c) =>
              c.includes('fascia')
            ) !== -1 &&
            detail.billingType !== 'ELE_ORARIO'
          ) {
            this.isFascia = true
          }
        })
        .catch((error) => {
          GenErr.handleGenericError(error && error.message)
        })
      this.getCadastralData({
        clientOwnerCode: this.$route.params.clientOwnerCode,
        supplyCode: this.$route.params.supplyCode
      }).then((res) => {
        let currentSupply = this.supplies.filter(
          (item) => item.supplyCode === this.$route.params.supplyCode
        )[0]
        if (
          (currentSupply.role === 'I' || currentSupply.role === 'G') &&
          (currentSupply.mainRole === '1' || currentSupply.mainRole === '2')
        ) {
          let gasAction = {
            name: 'Dati catastali',
            filename: 'daticatastali.svg',
            route: res.ricezioneDatiCatastali
              ? 'cadastralSuccess'
              : 'cadastralAction',
            trackingEventName: 'supplyDetail_sendCadastral_tap'
          }
          let eleAction = {
            name: 'Dati catastali',
            filename: 'daticatastali.svg',
            route: res.ricezioneDatiCatastali
              ? 'cadastralSuccess'
              : 'cadastralAction',
            trackingEventName: 'supplyDetail_cadastralAction_tap'
          }
          this.gasActions.push(gasAction)
          this.lightActions.push(eleAction)
        }
      })

      this.getChannelInfo({
        channel: constants.CALLCENTER_CHANNELS.LANDLINE,
        area: constants.CALLCENTER_AREAS.SUPPORT
      }).then((res) => {
        this.landline = res.contact
      })
      this.getChannelInfo({
        channel: constants.CALLCENTER_CHANNELS.MOBILE,
        area: constants.CALLCENTER_AREAS.SUPPORT
      }).then((res) => {
        this.mobile = res.contact
      })

      this.bidgely = this.setBidgely()

      // set bidgely status for service_detail_viewed
      let serviceType =
        this.supplyDetail[
          this.$route.params.supplyCode
        ].serviceType.toLowerCase()
      if (
        serviceType !== 'ele' ||
        this.clientDetail.clientType.toLowerCase() !== 'res'
      ) {
        console.log('undefined')
        this.bidgelyStatus = undefined
      } else {
        if (
          this.CheckSupplyPermission.consentsNotGranted(
            this.detail.activationDate
          ) ||
          !this.clientDetail.profilingConsentFlag ||
          !this.clientDetail.marketingConsentFlag
        ) {
          console.log('consents_not_granted')
          this.bidgelyStatus = 'consents_not_granted'
        } else if (
          this.CheckSupplyPermission.tooEarly(
            this.detail.activationDate,
            this.mainClient.mainRole
          ) ||
          !this.checkActivationdDate
        ) {
          console.log('too_early')
          this.bidgelyStatus = 'too_early'
        } else {
          console.log('normal')
          this.bidgelyStatus = 'normal'
        }
      }
      this.trackTapEvent({
        name: 'service_detail_viewed',
        params: {
          service_type: serviceType === 'fiber' ? 'fibra' : serviceType,
          product_code: this.detail.product,
          bidgely_status: this.bidgelyStatus
        }
      })
      this.loaded = true
    },

    paymentLabel(val) {
      return paymentTypeLabel(val)
    },
    formatPhoneNumber(value) {
      if (value) {
        let val = value.split('').reverse()
        let lenghtNumber = val.length
        let interval = 3
        let returnNumber = ''
        for (let i = 1; i <= lenghtNumber; i++) {
          if (i % interval === 0) {
            if (i < lenghtNumber) {
              returnNumber = returnNumber + val[i - 1] + '.'
            } else {
              returnNumber = returnNumber + val[i - 1]
            }
            if (
              lenghtNumber - i - interval > 0 &&
              lenghtNumber - i - interval < interval
            ) {
              interval = 2
            }
          } else {
            returnNumber = returnNumber + val[i - 1]
          }
        }
        return returnNumber.split('').reverse().join('')
      }
    },
    ...mapActions('payment', ['getPdrInfo']),
    ...mapActions('interactions', ['isChannelOpen', 'getChannelInfo']),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('account', [
      'getContractsForClient',
      'getNotDigitalContracts',
      'getClientsForAccount',
      'getDetailForClient',
      'getMainClient'
    ]),
    ...mapActions('MGM', ['sendPromoCode']),
    ...mapActions('supply', [
      'getSupplies',
      'getCadastralData',
      'retrieveParentalControlStatus',
      'getDetail'
    ]),

    countTotalScaduteInstalments() {
      // uso array mergiato tra pdr e bollette
      let onlyPdr = this.filteredAll.slice(0, 3).filter((item) => {
        return item.planStartDate
      })
      return onlyPdr.forEach((item) => {
        item.instalments.forEach((instalment) => {
          if (
            instalment.instalmentStatus === constants.STATUS_PDR.EXPIRED &&
            !instalment.checkPaymentInstalment
          ) {
            this.rateScadute++
          }
        })
      })
    },

    goToPagamenti(detail) {
      // se ho bollette scadute vai a tab bollette
      // se ho rate scadute vai a tab piani di rientro
      // se ho sia rate scadute che bollette scadute vai a bollette
      // se non ho ne bollette ne rate scadute vai a bollette
      if (this.currentSupply.notRegularPayments === 0 && this.rateScadute > 0) {
        this.billsArchive(
          detail.clientOwnerCode,
          detail.supplyCode,
          false,
          true,
          'pdr'
        )
      } else {
        this.billsArchive(
          detail.clientOwnerCode,
          detail.supplyCode,
          true,
          this.rateScadute > 0,
          'bollette'
        )
      }
    },

    goToRiepilogo(detail) {
      let valueTab = 'bollette'
      // se ho bollette scadute vai a tab bollette
      // se ho rate scadute vai a tab piani di rientro
      // se ho sia rate scadute che bollette scadute vai a bollette
      // se non ho ne bollette ne rate scadute vai a bollette
      if (this.currentSupply.notRegularPayments === 0 && this.rateScadute > 0) {
        valueTab = 'pdr'
      }
      this.billsArchive(
        detail.clientOwnerCode,
        detail.supplyCode,
        false,
        false,
        valueTab
      ),
        this.trackTapEvent({
          name: 'supplyDetail_billArchive_tap',
          params: this.getSupplyTrackingParams(detail)
        })
    },

    setBidgely() {
      const prefix =
        'Il servizio di monitoraggio <b>Beyond Energy</b> ti propone alcuni consigli per gestire al meglio i consumi della tua casa. '
      if (!this.belongClient) {
        this.trackTapEvent({
          name: 'supplyDetail_bidgelyCallUs_tap',
          params: getSupplyTrackingParams(this.detail)
        })
        return {
          text: prefix + 'Contattaci per abilitare il servizio.',
          cta: 'Migliora i tuoi consumi',
          action: 'contactme'
        }
      } else if (
        this.CheckSupplyPermission.NoPrivacyNoSupplyAfterOrEqual2Month(
          this.detail.activationDate
        )
      ) {
        return {
          text:
            prefix +
            'Fornisci i consensi per accedere al servizio, sarà disponibile entro 24 ore.',
          cta: 'Vai ai consensi',
          action: 'account'
        }
      } else if (
        this.CheckSupplyPermission.NoPrivacyNoSupplyBefore2Month(
          this.detail.activationDate
        )
      ) {
        return {
          text:
            prefix +
            'Fornisci i consensi per accedere al servizio, sarà disponibile dopo 2 mesi di fornitura.',
          cta: 'Vai ai consensi',
          action: 'account'
        }
      } else if (
        this.CheckSupplyPermission.NoPrivacyYesSupplyAfterOrEqual4Month(
          this.detail.activationDate
        )
      ) {
        return {
          text:
            prefix +
            'Fornisci i consensi per accedere al servizio, sarà disponibile entro 24 ore.',
          cta: 'Vai ai consensi',
          action: 'account'
        }
      } else if (
        this.CheckSupplyPermission.NoPrivacyYesSupplyBefore4Month(
          this.detail.activationDate
        )
      ) {
        return {
          text:
            prefix +
            'Fornisci i consensi per accedere al servizio, sarà disponibile dopo 4 mesi di fornitura.',
          cta: 'Vai ai consensi',
          action: 'account'
        }
      } else if (
        this.CheckSupplyPermission.YesPrivacyNoMainRoleNoSupplyAfterOrEqual2Month(
          this.detail.activationDate,
          this.mainClient.mainRole
        )
      ) {
        this.trackTapEvent({
          name: 'supplyDetail_bidgelyCallUs_tap',
          params: getSupplyTrackingParams(this.detail)
        })
        return {
          text:
            prefix +
            "Contattaci, un nostro operatore ti seguirà nell'attivazione del servizio gratuito.",
          cta: 'Migliora i tuoi consumi',
          action: 'contactme'
        }
      } else if (
        this.CheckSupplyPermission.YesPrivacyNoMainRoleNoSupplyBefore2Month(
          this.detail.activationDate,
          this.mainClient.mainRole
        )
      ) {
        this.trackTapEvent({
          name: 'supplyDetail_bidgelyCallUs_tap',
          params: getSupplyTrackingParams(this.detail)
        })
        return {
          text:
            prefix +
            "Contattaci, un nostro operatore ti seguirà nell'attivazione del servizio gratuito.",
          cta: 'Migliora i tuoi consumi',
          action: 'contactme'
        }
      } else if (
        this.CheckSupplyPermission.YesPrivacyNoMainRoleYesSupplyAfterOrEqual4Month(
          this.detail.activationDate,
          this.mainClient.mainRole
        )
      ) {
        this.trackTapEvent({
          name: 'supplyDetail_bidgelyCallUs_tap',
          params: getSupplyTrackingParams(this.detail)
        })
        return {
          text:
            prefix +
            "Contattaci, un nostro operatore ti seguirà nell'attivazione del servizio gratuito.",
          cta: 'Migliora i tuoi consumi',
          action: 'contactme'
        }
      } else if (
        this.CheckSupplyPermission.YesPrivacyNoMainRoleYesSupplyBefore4Month(
          this.detail.activationDate,
          this.mainClient.mainRole
        )
      ) {
        this.trackTapEvent({
          name: 'supplyDetail_bidgelyCallUs_tap',
          params: getSupplyTrackingParams(this.detail)
        })
        return {
          text:
            prefix +
            "Contattaci, un nostro operatore ti seguirà nell'attivazione del servizio gratuito.",
          cta: 'Migliora i tuoi consumi',
          action: 'contactme'
        }
      } else if (
        this.CheckSupplyPermission.YesPrivacyYesMainRoleNoSupplyAfterOrEqual2Month(
          this.detail.activationDate,
          this.mainClient.mainRole
        )
      ) {
        return {
          text: prefix,
          cta: 'Migliora i tuoi consumi',
          action: 'supplyBidgely'
        }
      } else if (
        this.CheckSupplyPermission.YesPrivacyYesMainRoleNoSupplyBefore2Month(
          this.detail.activationDate,
          this.mainClient.mainRole
        )
      ) {
        return {
          text:
            prefix +
            'Il servizio sarà disponibile dopo i primi 2 mesi di fornitura.',
          cta: '',
          action: ''
        }
      } else if (
        this.CheckSupplyPermission.YesPrivacyYesMainRoleYesSupplyAfterOrEqual4Month(
          this.detail.activationDate,
          this.mainClient.mainRole
        )
      ) {
        return {
          text: prefix,
          cta: 'Migliora i tuoi consumi',
          action: 'supplyBidgely'
        }
      } else if (
        this.CheckSupplyPermission.YesPrivacyYesMainRoleYesSupplyBefore4Month(
          this.detail.activationDate,
          this.mainClient.mainRole
        )
      ) {
        return {
          text:
            prefix +
            'Il servizio sarà disponibile dopo i primi 4 mesi di fornitura.',
          cta: '',
          action: ''
        }
      } else if (
        !this.clientDetail.profilingConsentFlag ||
        !this.clientDetail.marketingConsentFlag
      ) {
        return {
          text: prefix + 'Attiva il servizio fornendo i consensi.',
          cta: 'Vai ai consensi',
          action: 'account'
        }
      } else if (!this.checkActivationdDate) {
        return {
          text:
            prefix +
            'Il servizio sarà disponibile dopo i primi due mesi di fornitura.',
          cta: '',
          action: ''
        }
      } else {
        return {
          text: prefix,
          cta: 'Migliora i tuoi consumi',
          action: 'contactme'
        }
      }
    },
    sendCode() {
      this.trackTapEvent({
        name: 'mgmdiscount_mainCTA_tap',
        params: getSupplyTrackingParams(this.detail)
      })
      let segmentoCliente =
        (this.detail.clientOwnerType === 'RES' ? 'Residenziale' : 'Business') +
        ' ' +
        (this.clientDetail.hasDigitalPoints ? 'Digital' : 'NoDigital')
      this.sendPromoCode({
        codicePromo: this.promoCode,
        nome:
          this.detail.clientOwnerType === 'RES'
            ? this.detail.firstName
            : this.detail.businessName,
        cognome:
          this.detail.clientOwnerType === 'RES'
            ? this.detail.lastName
            : this.detail.businessName,
        commodity: this.detail.serviceType,
        email: this.clientDetail.contactMail,
        indirizzoDiFornitura: this.detail.address,
        codiceCliente: this.detail.clientOwnerCode,
        segmentoCliente: segmentoCliente,
        codicePunto: this.detail.supplyCode
      })
        .then((res) => {
          if (res.status === 'OK' && res.errorCode !== '003') {
            this.errorPromoCode = res
            if (!res.errorCode) {
              this.trackTapEvent({
                name: 'mgmdiscount_thankyoupage_open',
                params: getSupplyTrackingParams(this.detail)
              })
              this.dialogPromoCode = {
                show: true,
                result: 'OK',
                importo: res.importo,
                nRate: res.nRate
              }
            }
          } else {
            this.errorPromoCode = res
            this.dialogPromoCode = {
              show: true,
              result: 'KO'
            }
          }
        })
        .catch(() => {
          this.dialogPromoCode = {
            show: true,
            result: 'KO'
          }
        })
    },
    openDialogHelp(id) {
      this.contextualHelpId = id
      this.dialogHelp = true
    },
    filteredBillsOfLast2Years(bills) {
      let endDate = new Date()
      let startDate = new Date().setMonth(endDate.getMonth() - 48)
      let filtered = bills.filter((bill) => {
        let dataEmiss = new Date(
          bill.dataInizioCompetenza.split('/').reverse().join('-')
        )
        bill.sortableDate = dataEmiss
        return dataEmiss >= startDate && dataEmiss <= endDate
      })
      return filtered.sort(dynamicSort('-sortableDate'))
    },

    requestCredit() {
      this.$router.push({
        name: 'requestCredit',
        params: {
          clientOwnerCode: this.currentSupply.clientOwnerCode,
          supplyCode: this.currentSupply.supplyCode
        }
      })
    },
    dataAlert(text) {
      this.selfReadingDialog = {
        show: true,
        title: 'Comunica autolettura',
        text: text
      }
    },
    lenghtActions() {
      return this.actions.length > 4 ? 4 : this.actions.length
    },
    getProdutcs(value) {
      return constants.PRODUCTS[value] === undefined
        ? this.detail.offerName
        : constants.PRODUCTS[value]
    },
    billsArchive(
      clientOwnerCode,
      supplyCode,
      expiredBills,
      expiredPdr,
      valueTab
    ) {
      if (expiredBills || expiredPdr) {
        router.push({
          name: 'billsArchive',
          params: {
            clientOwnerCode: clientOwnerCode,
            supplyCode: supplyCode,
            expiredBillsFilter: expiredBills,
            expiredPdrFilter: expiredPdr,
            valueTab: valueTab
          }
        })
      } else {
        router.push({
          name: 'billsArchive',
          params: {
            clientOwnerCode: clientOwnerCode,
            supplyCode: supplyCode,
            valueTab: valueTab
          }
        })
      }
    },
    actionBidgely(action) {
      if (action === 'account') {
        this.$router.push({
          name: 'account',
          params: {
            showConsentsClient: true,
            clientOwnerCode: this.$route.params.clientOwnerCode
          }
        })
        this.trackTapEvent({
          name: 'supplyDetail_bidgelyGoToConsents_tap',
          params: getSupplyTrackingParams(this.detail)
        })
      } else if (action === 'contactme') {
        this.dialogContact = true
        this.trackTapEvent({
          name: 'supplyDetail_bidgelyActivate_tap',
          params: getSupplyTrackingParams(this.detail)
        })
      } else if (action === 'supplyBidgely') {
        this.$router.push({ name: 'supplyBidgely' })
        this.trackTapEvent({
          name: 'supplyDetail_bidgely_tap',
          params: getSupplyTrackingParams(this.detail)
        })
      }
    },
    closeDialog() {
      this.dialog.show = false
      this.trackTapEvent({
        name: this.dialog.tracking,
        params: getSupplyTrackingParams(this.detail)
      })
    },
    getCurveConsumi: function (data) {
      this.showChartLoader = true
      let promArr = []
      let paramsCompare = {}
      // let paramsPotenza = {}
      let paramsDate = {
        clientOwnerCode: this.$route.params.clientOwnerCode,
        supplyCode: this.$route.params.supplyCode,
        endDate: new Intl.DateTimeFormat('it', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        }).format(data['paramsDate'].dateEnd),
        startDate: new Intl.DateTimeFormat('it', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        }).format(data['paramsDate'].dateStart),
        frazione: data['paramsDate'].frazione
      }
      // let totalConsumptionOriginal = 0
      // let totalConsumptionCompare = 0
      let originalConsumption = this.$store
        .dispatch('supply/getCurveConsumi', paramsDate)
        .then((curve) => {
          // totalConsumptionOriginal = curve.total
          return curve
        })
      promArr.push(originalConsumption)
      if ('paramsCompare' in data) {
        paramsCompare = {
          clientOwnerCode: this.$route.params.clientOwnerCode,
          supplyCode: this.$route.params.supplyCode,
          endDate: new Intl.DateTimeFormat('it', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          }).format(data['paramsCompare'].dateEnd),
          startDate: new Intl.DateTimeFormat('it', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          }).format(data['paramsCompare'].dateStart),
          frazione: data['paramsCompare'].frazione
        }
        let comparedComsumption = this.$store
          .dispatch('supply/getCurveConsumi', paramsCompare)
          .then((curve) => {
            // totalConsumptionCompare = curve.total
            return curve
          })
        promArr.push(comparedComsumption)
        Promise.all([originalConsumption, comparedComsumption]).then(
          (results) => {
            this.consumptionVariation = (
              ((results[1].total - results[0].total) / results[0].total) *
              100
            ).toFixed(2)
          }
        )
      }
      // if (data['paramsDate'].frazione === "4") {
      //   let _minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 2))
      //   let _activationDate = new Date(this.detail.activationDate)
      //   if (_activationDate && _activationDate > _minDate) {
      //       _minDate = _activationDate
      //   }
      //   paramsPotenza = {
      //     clientOwnerCode: this.$route.params.clientOwnerCode,
      //     supplyCode: this.$route.params.supplyCode,
      //     endDate: new Intl.DateTimeFormat('it',{day: '2-digit', month: '2-digit', year: 'numeric'}).format(new Date()),
      //     startDate: new Intl.DateTimeFormat('it',{day: '2-digit', month: '2-digit', year: 'numeric'}).format(_minDate),
      //     frazione: "1"
      //   }
      //   promArr.push(this.$store.dispatch('supply/getCurveConsumi', paramsPotenza).then(curve => {return curve}))
      // }
      Promise.all(promArr).then((prom) => {
        this.curveConsumi = prom[0]
        this.curveConsumiCompare = prom[1]
        this.curvePotenza = prom[0]

        if (
          prom[0].curve.length > 0 &&
          Object.keys(prom[0].curve[0]).findIndex((c) =>
            c.includes('fascia')
          ) !== -1 &&
          this.detail.billingType !== 'ELE_ORARIO'
        ) {
          this.isFascia = true
        }
        this.keyConsumo++
        this.keyPotenza++
        this.showChartLoader = false
      })
    },
    deleteSerieCompare(type) {
      if (type === 'from') {
        this.curveConsumi = this.curveConsumiCompare
        this.curvePotenza = this.curveConsumi
      }
      this.curveConsumiCompare = null
      this.keyConsumo++
      this.keyPotenza++
    },
    export() {
      this.trackTapEvent({
        name: 'supplyDetail_chartDownload_tap',
        params: getSupplyTrackingParams(this.detail)
      })
      if (this.curveConsumi.curve && this.curveConsumi.curve.length === 0) {
        this.noDataToExport = true
        return false
      }
      let data = []
      let optionsFormat = { day: '2-digit', month: '2-digit', year: 'numeric' }
      if (
        this.curveConsumi.frazione === '3' ||
        this.curveConsumi.frazione === '4'
      ) {
        optionsFormat = {
          ...optionsFormat,
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: false
        }
      }
      if (this.tab === 0) {
        if (this.curveConsumiCompare) {
          let maxSerie = this.curveConsumiCompare.curve
          if (
            this.curveConsumi.curve.length >
            this.curveConsumiCompare.curve.length
          ) {
            maxSerie = this.curveConsumi.curve
          }
          for (let i = 0; i < maxSerie.length; i++) {
            let jsonData = {
              'Data inizio': this.curveConsumi.curve[i]
                ? new Intl.DateTimeFormat('it', optionsFormat).format(
                    new Date(this.curveConsumi.curve[i].startDate)
                  )
                : '',
              'Data fine': this.curveConsumi.curve[i]
                ? new Intl.DateTimeFormat('it', optionsFormat).format(
                    new Date(this.curveConsumi.curve[i].endDate)
                  )
                : '',
              Consumo: this.curveConsumi.curve[i]
                ? this.curveConsumi.curve[i].curveconsumo
                : ''
            }
            if (
              this.detail.serviceType === 'Ele' &&
              this.curveConsumi.frazione !== '4'
            ) {
              jsonData['Picco'] = this.curveConsumi.curve[i]
                ? this.curveConsumi.curve[i].piccopotenza
                : ''
            }
            jsonData = {
              ...jsonData,
              'Data inizio ': this.curveConsumiCompare.curve[i]
                ? new Intl.DateTimeFormat('it', optionsFormat).format(
                    new Date(this.curveConsumiCompare.curve[i].startDate)
                  )
                : '',
              'Data fine ': this.curveConsumiCompare.curve[i]
                ? new Intl.DateTimeFormat('it', optionsFormat).format(
                    new Date(this.curveConsumiCompare.curve[i].endDate)
                  )
                : '',
              'Consumo ': this.curveConsumiCompare.curve[i]
                ? this.curveConsumiCompare.curve[i].curveconsumo
                : ''
            }
            if (
              this.detail.serviceType === 'Ele' &&
              this.curveConsumi.frazione !== '4'
            ) {
              jsonData['Picco '] = this.curveConsumiCompare.curve[i]
                ? this.curveConsumiCompare.curve[i].piccopotenza
                : ''
            }
            data.push(jsonData)
          }
        } else {
          data = this.curveConsumi.curve.map((curve) => {
            let dataRet
            if (
              ('fascia1' && 'fascia2' && 'fascia3') in curve &&
              this.detail.billingType !== 'ELE_ORARIO'
            ) {
              dataRet = {
                'Data inizio': curve.startDate
                  ? new Intl.DateTimeFormat('it', optionsFormat).format(
                      new Date(curve.startDate)
                    )
                  : '',
                'Data fine': curve.endDate
                  ? new Intl.DateTimeFormat('it', optionsFormat).format(
                      new Date(curve.endDate)
                    )
                  : '',
                'Fascia 1': curve.fascia1,
                'Fascia 2': curve.fascia2,
                'Fascia 3': curve.fascia3,
                Consumo: curve.curveconsumo
              }
            } else {
              dataRet = {
                'Data inizio': curve.startDate
                  ? new Intl.DateTimeFormat('it', optionsFormat).format(
                      new Date(curve.startDate)
                    )
                  : '',
                'Data fine': curve.endDate
                  ? new Intl.DateTimeFormat('it', optionsFormat).format(
                      new Date(curve.endDate)
                    )
                  : '',
                Consumo: curve.curveconsumo
              }
            }
            if (
              this.detail.serviceType === 'Ele' &&
              this.curveConsumi.frazione !== '4'
            ) {
              dataRet['Picco'] = curve.piccopotenza
            }
            return dataRet
          })
        }
      } else {
        data = this.curveConsumi.curve.map((curve) => ({
          'Data inizio': curve.startDate
            ? new Intl.DateTimeFormat('it', optionsFormat).format(
                new Date(curve.startDate)
              )
            : '',
          'Data fine': curve.endDate
            ? new Intl.DateTimeFormat('it', optionsFormat).format(
                new Date(curve.endDate)
              )
            : '',
          Consumo: curve.curveconsumo,
          Picco: this.curveConsumi.frazione !== '4' ? curve.piccopotenza : '',
          Potenza: parseFloat(this.detail.power) || 3
        }))
      }
      const options = {
        filename: 'Grafico dei consumi',
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true
      }
      // const csvExporter = new ExportToCsv(options)
      // csvExporter.generateCsv(data)
      const csv = generateCsv(options)(data)
      download(options)(csv)
    }
  },
  components: {
    UserDetail,
    ListPdrBills,
    Chart,
    ChartNew,
    ChartUtils,
    Carousel,
    Slide,
    TcVars,
    DialogContextualHelp,
    PunCard
  },
  validations: {
    promoCode: { required, hasCharacterValid }
  }
}
</script>

<style lang="scss" scoped>
.chart-loader {
  display: flex;
  margin: 0 auto;
}

.textLoader {
  font-size: 18px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 50px auto 20px;
}

.v-tab {
  max-width: 50% !important;
}

.customTab {
  font-size: 20px;
  color: black;
  text-transform: none;
  font-weight: normal;
}

.v-tab.v-tab--active {
  .customTab {
    color: #12256a;
    font-weight: bold;
  }
}

.std-panel {
  .bills__iteams {
    border-right: 0 !important;
  }
  &.notRegular {
    border: 1px solid $srg-purple;
  }
}
.std-side-padding--ceased {
  padding-left: calc((100% - 1340px) / 2);
  padding-right: calc((100% - 1340px) / 2);

  .ceased {
    background: $srg-purple;
    box-shadow: 0 10px 15px 0 #d4d4d4;
    margin-bottom: 30px;

    h4 {
      color: white;
      font-weight: bold;
    }
  }
}

.unavailable {
  padding-top: 10px;
  background: $srg-blue;
  padding-top: 12px;
  padding-bottom: 46px;
  color: white;

  h5 {
    font-size: 20px;
  }
}

.available {
  margin-top: 12px;
  background: $srg-blue;
  padding-bottom: 30px;
  color: white;

  h5 {
    font-size: 20px;
    font-weight: bold;
  }
}

.empty {
  background: #fff;
}

.topRadius {
  height: 100%;
}

.supply-detail {
  color: black;
}

.std-side-padding {
  overflow: inherit;
}

.user {
  color: black;
}

.btnSelfReading {
  font-size: 18px;
  letter-spacing: 1.2px;
  font-weight: bold;

  span {
    color: $srg-blue;
  }
}

.selfReading {
  h5 {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
  }

  h3 {
    letter-spacing: 1px;
  }

  .cursor-pointer {
    cursor: pointer;
  }
}

.bills,
.actions,
.supply-info,
.selfReading,
.bidgely {
  //margin-top: 30px;

  .bills__title,
  .action__title,
  .info__title,
  .selfReading__title {
    font-weight: bold;
    letter-spacing: 1.2px;
  }

  .bills__desc {
    font-size: 20px;
  }

  .selfReading_info {
    cursor: pointer;

    &_btn {
      border: 1px grey solid;
      border-radius: 50%;

      &:hover {
        border: 2px grey solid;
        background-color: #dbdbdb;
        margin-left: -2px;
        transform: translateX(1px);
      }
    }
  }

  .selfReading_dateReading {
    font-weight: bold;
  }

  .bills__archive,
  .action__archive {
    text-align: right;

    .bills__btn {
      height: 50px;
      min-width: 245px;

      &.expiredBills {
        min-width: 315px;

        span {
          width: 24px;
          height: 24px;
          background-color: #d93273;
          border-radius: 12px;
          font-size: 12px;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 10px;
        }
      }
    }

    .larger-btn {
      width: 300px;
    }
  }

  .bills__list {
    padding-top: 0;

    .empty_text {
      background-color: #fff;
      font-size: 20px;
    }

    .bills__iteams {
      border-right: 1px solid lightgray;

      .col {
        padding-bottom: 0;
      }

      .bills__item-bold {
        h5,
        h4,
        h3,
        h2,
        span {
          font-weight: bold;
        }

        span {
          font-size: $title-font-size--small;
        }

        .col {
          padding-bottom: 0;
        }
      }

      .bills__statusPay {
        text-transform: uppercase;
        font-size: $title-font-size--small;

        &--1 {
          color: $srg-purple;
        }

        &--2 {
          color: $srg-purple;
        }

        &--3 {
          color: $srg-purple;
        }

        &--4 {
          color: black;
        }

        &--5 {
          color: $srg-purple;
        }
      }
    }

    .bills__link {
      text-align: center;
    }
  }

  .bidgely-btn {
    justify-self: end;
    margin-bottom: -8px;
    letter-spacing: 1.2px;
    font-size: 18px;
    font-weight: bold;
  }
}

.actions {
  .action-panel {
    padding: 24px 0px 0px 0px;
    height: 170px;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px 0 rgba(44, 53, 66, 0.08),
      0 1px 8px 0 rgba(44, 53, 66, 0.08), 0 2px 24px 0 rgba(44, 53, 66, 0.06);
    border-radius: 32px;
    cursor: pointer;
  }

  .action__img {
    height: auto;
    width: 72px;
  }

  .action__type {
    margin-top: 20px;
    border-top: 1px solid #dfe4ef;
    width: 100%;
    text-align: center;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;

    h5 {
      font-size: 14px;
      letter-spacing: 1.2px;
      font-weight: bold;
      line-height: 1.3;
      padding: 0 24px;
    }
  }
}

.rewards {
  margin-top: 12px;
  background: #eaeef5;
  padding-bottom: 30px;
  color: black;
  padding-top: 38px;

  h5 {
    font-weight: 500;
    cursor: pointer;
  }

  .desc {
    font-size: 20px;
  }

  .inputCode {
    width: 455px;
  }
}

.supply-info {
  .info__list {
    .info__item {
      h5 {
        font-weight: bold;

        &.color--blue {
          cursor: pointer;
        }
      }

      .col {
        padding-right: 30px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.supply-rejected {
  &__text {
    height: 153px;
    width: 100%;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 70px;
  }

  h5 {
    font-size: 20px;
    color: black;
    line-height: 1.4;
    letter-spacing: 1px;
    text-align: center;

    &.help {
      margin-top: 20px;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 1.2;
      cursor: pointer;
    }
  }

  button {
    margin-top: 90px;
  }
}

#chart {
  width: 100%;
}

.chart {
  margin-top: 30px;
  width: 100%;

  .legenda {
    width: 445px;
    height: 56px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    //gap: 16px;
    padding: 16px;
    border-radius: 20px;
    background-color: #f8f9fc;
  }

  .MediumCircle {
    width: 24px;
    height: 24px;
    flex-grow: 0;
    object-fit: contain;
    margin-right: 10px;
  }

  .spese-anno-corrente {
    width: 171px;
    height: 14px;
    flex-grow: 0;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 1px;
    text-align: left;
    opacity: 0.4;
    text-transform: uppercase;
  }

  .chart-switch {
    &__label {
      font-size: 18px;
      line-height: 1.4;
      letter-spacing: 1.2px;
      color: $srg-blue;
      margin-right: 20px;
      font-weight: bold;
    }
  }

  .chart-title {
    margin-bottom: 30px;

    h2 {
      font-weight: bold;
      letter-spacing: 1.2px;
      margin-bottom: 16px;
    }

    h5 {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 1px;
    }
  }

  &--empty {
    height: 300px;
    width: 100%;
    background-size: contain;
    background-image: url('../../assets/bg_placeholderChart.png');
    background-position: center;

    h5 {
      display: none;
    }

    span {
      font-size: 20px;
      display: block;
      margin: 0 auto;
      align-self: flex-end;
      margin-bottom: 32px;
    }
  }
}

.chart-message {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  font-style: italic;
}

.banner-fibra {
  margin-top: 30px;

  .banner-fibra__big {
    font-weight: bold;
    font-size: 70px;
  }

  span {
    font-size: 24px;
  }
}

.v-dialog__content {
  .supply-dialog {
    background: white;
    padding: 55px 45px 45px 55px;
    border-radius: 40px;
    position: relative;

    .small {
      padding: 55px 110px 55px 55px;
    }

    h2 {
      font-weight: bold;
      margin-bottom: 20px;
    }

    h4 {
      span {
        font-weight: bold;
      }
    }

    img {
      position: absolute;
      top: 40px;
      right: 40px;
      cursor: pointer;

      &.preview {
        width: 652px;
        position: initial;
      }
    }
  }

  .promo-dialog {
    background: white;
    padding: 55px 45px 45px 55px;
    border-radius: 40px;
    position: relative;

    .small {
      padding: 55px 110px 55px 55px;
    }

    h2 {
      font-weight: bold;
      margin-bottom: 20px;
    }

    .desc {
      font-size: 20px;

      span {
        text-transform: capitalize;
      }
    }

    .close {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;

      &.preview {
        position: initial;
      }
    }
  }

  .self-reading-dialog {
    p {
      font-size: 20px;
      color: black;
    }

    .dialog__img {
      cursor: pointer;
      margin-top: 20px;
      margin-right: 20px;
    }
  }
}
</style>
