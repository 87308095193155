<template>
  <div class="homeNew">
    <TcVars />
    <div class="homeNew__bkg-1">
      <div class="std-side-padding">
        <div
          class="menu__link"
          @click="
            trackTapEvent('home_account_tap'), $router.push({ name: 'account' })
          "
        >
          <div class="homeNew__welcome HeadingL-Upper">
            <span class="entry-white" v-if="welcomeNameIsRes"> CIAO </span>
            <span>{{ welcomeName.toUpperCase() }}</span>
          </div>
        </div>
        <div class="homeNew__supplies mb-8">
          <SupplyCard :loaded="loadedSupplies"></SupplyCard>
          <OfferCard :loaded="loadedSupplies"></OfferCard>
        </div>
      </div>
    </div>
    <div
      class="homeNew__bkg-2"
      v-if="mainClient?.clientOwnerCode !== mainClient?.clientCodeFV"
    >
      <div class="std-side-padding">
        <div class="homeNew__initiative mb-6">Iniziative sostenibili</div>
        <div class="notCarousel">
          <GreenersCard
            v-if="showLoyalty"
            :loaded="loadedInitiative"
            :initiative="accountInitiative.greeners"
          ></GreenersCard>
          <CheckupCard
            @redirectToCheckup="redirectToCheckup"
            :loaded="loadedInitiative"
          ></CheckupCard>
          <CfpCard
            v-if="showCfp && memberStatus.statusGreeners !== 'Pending'"
            :loaded="loadedInitiative"
            :initiative="accountInitiative.cfp"
          ></CfpCard>
          <MyShopCard v-if="showMyShop" :loaded="loadedInitiative"></MyShopCard>
        </div>
      </div>
    </div>
    <div class="std-side-padding">
      <div class="homeNew__news mb-6">Notizie da Sorgenia</div>
    </div>
    <div class="homeNew__carousel">
      <Promo :loaded="loadedPromo" />
    </div>
    <OnBoardingHomeVue
      v-if="Object.keys(accountProperties).length > 0"
      :dialog="dialogOnboarding"
      @close="closeDialogOnboarding"
    />
    <SelfReadingModal :selfReadingModalOpen.sync="selfReadingModal" />
  </div>
</template>
<script>
import { eventBus } from '@/main'

import OfferCard from './supplies/OfferCard.vue'
import SupplyCard from './supplies/SupplyCard.vue'

import CfpCard from './initiative/CfpCard.vue'
import CheckupCard from './initiative/CheckupCard.vue'
import GreenersCard from './initiative/GreenersCard.vue'
import MyShopCard from './initiative/MyShopCard.vue'

import TcVars from '@/components/tcTracker/TcVars'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Promo from './promo/Promo.vue'
import OnBoardingHomeVue from '../../components/onboarding/OnBoardingHome.vue'
import Constants from '@/js/constants'
import SelfReadingModal from '../../components/home/SelfReadingModal.vue'
export default {
  name: 'homeNew',
  components: {
    SupplyCard,
    OfferCard,
    GreenersCard,
    CfpCard,
    MyShopCard,
    CheckupCard,
    Promo,
    TcVars,
    OnBoardingHomeVue,
    SelfReadingModal
    // GreenersPendingDialog,
    // CheckupDialog
  },
  data() {
    return {
      loadedSupplies: false,
      loadedInitiative: false,
      loadedPromo: false,
      isFromLogin: false,
      dialogOnboarding: false,
      dialogUserGreenersPending: false,
      nextLabel: '<div class="icon next"/>',
      prevLabel: '<div class="icon prev"/>',
      isDeepLinkCheckup: false,
      selfReadingModal: false
    }
  },
  computed: {
    ...mapGetters('account', [
      'mainClient',
      'mainClientLoyaltySelected',
      'mainClientLoyaltyHome',
      'accountInitiative',
      'accountProperties',
      'listMainClientLoyalty'
    ]),
    ...mapGetters('supply', ['supplies', 'isNotAllPlacet']),
    ...mapGetters('myshop', ['myShopEnabled', 'myShopAudience']),
    ...mapGetters('session', ['token']),
    ...mapGetters('loyalty', ['memberStatus']),
    ...mapGetters('checkup', ['getCheckupMounted']),
    showCfp() {
      return this.isNotAllPlacet || !this.loadedInitiative
    },
    showMyShop() {
      if (!this.accountProperties.type) return false || !this.loadedInitiative
      return (
        (this.myShopEnabled && this.showAudienceFilter) ||
        !this.loadedInitiative
      )
    },
    showAudienceFilter() {
      return this.currentClientTypes.some((item) =>
        this.myShopAudience.toLowerCase().includes(item.toLowerCase())
      )
    },
    currentClientTypes() {
      if (!this.accountProperties.type) return ''
      let isClientDigital = this.accountProperties.hasAtLeast1Digital
      let isClientPlacet = !this.isNotAllPlacet
      let clientTypeStr = this.accountProperties.type

      let suffix = isClientPlacet
        ? 'placet'
        : isClientDigital
        ? 'digital'
        : 'nondigital'

      let clientTypes =
        clientTypeStr === 'MIXED'
          ? ['RES-' + suffix, 'BUS-' + suffix]
          : [clientTypeStr + '-' + suffix]

      return clientTypes
    },
    welcomeNameIsRes() {
      return this.mainClient ? this.mainClient.clientOwnerType === 'RES' : ''
    },
    welcomeName() {
      return this.mainClient
        ? this.mainClient.firstName || this.mainClient.businessName || ''
        : ''
    },
    showLoyalty() {
      let listMain = this.listMainClientLoyalty.map((m) => m.clientOwnerCode)
      return (
        (this.isNotAllPlacet &&
          this.supplies.filter((sup) => {
            return (
              (sup.supplyState === Constants.SUPPLY_STATUS.ACTIVE ||
                sup.supplyState === Constants.SUPPLY_STATUS.ACTIVATING) &&
              listMain.includes(sup.clientOwnerCode)
            )
          }).length > 0) ||
        !this.loadedInitiative
      )
    }
  },
  methods: {
    ...mapActions('account', [
      'getPromos',
      'getMainClient',
      'getAccountInitiative',
      'getFriendsList'
    ]),
    ...mapActions('loyalty', ['getTransactionsList', 'setLoyaltyStatus']),
    ...mapActions('supply', ['getSupplies']),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('myshop', ['getMyShopConfig']),
    ...mapMutations('account', ['setMainClientLoyaltySelected']),
    ...mapMutations('checkup', ['setCheckupMounted']),
    closeDialogOnboarding() {
      this.dialogOnboarding = false
    },
    tcVarReady() {
      if (this.isFromLogin) {
        if (window.tC) {
          window.tC.event.refreshAfterLogin()
        }
      }
    },
    redirectToCheckup() {
      if (!this.getCheckupMounted) {
        this.setCheckupMounted(true)
      } else {
        this.trackTapEvent({
          name: 'checkup_viewed'
        })
      }
      this.trackTapEvent({
        name: 'checkup_carousel_home_card'
      })
      eventBus.$emit('checkup:open')
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.name === 'deeplinkCheckup') {
        vm.isDeepLinkCheckup = true
      }
      vm.isFromLogin = from.path === '/login'
    })
  },
  beforeMount() {
    this.dialogUserGreenersPending = this.$route.params.showModalPending
    this.getMainClient().then(async () => {
      if (this.isDeepLinkCheckup) {
        this.redirectToCheckup()
      }
      await this.getSupplies()
      await this.getMyShopConfig()
      let onboardingStorage = JSON.parse(localStorage.getItem('onboarding'))
      if (
        (!onboardingStorage ||
          !onboardingStorage[this.mainClient.clientOwnerCode]) &&
        this.isFromLogin
      ) {
        if (this.$route.meta.openselfreading) {
          this.selfReadingModal = true
        } else {
          this.dialogOnboarding = true
        }
      }
      if (this.$route.meta.openCfp) {
        eventBus.$emit('footprint:open')
      }
      if (this.$route.meta.beyondenergyAlert) {
        this.$router.push({ name: 'home', params: { beyondenergyAlert: true } })
      }
      this.loadedSupplies = true
      this.getAccountInitiative().then(async () => {
        this.loadedInitiative = true
        await this.getPromos()
        this.loadedPromo = true
        this.trackTapEvent({
          name: 'home_viewed'
        })
      })
    })
  },
  mounted() {
    if (this.$route.meta.openselfreading) {
      this.selfReadingModal = true
      this.closeDialogOnboarding()
      // this.dialogOnboarding = false
    }
  }
}
</script>
<style lang="scss" scoped>
.homeNew {
  &__bkg-1 {
    position: relative;
    background: linear-gradient(180deg, $srg-green 176px, white 0px);
    z-index: 1;

    &::after {
      content: url('../../assets/new-home/shape-dx.svg');
      position: absolute;
      top: 0;
      right: 0;
      // right: calc(((100% - 1140px) / 2) - 160px);
      z-index: -1;
    }

    &::before {
      content: url('../../assets/new-home/shape-sx.svg');
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
    .menu__link {
      width: fit-content;
      cursor: pointer;
    }
  }

  .entry-white {
    color: $base-white;
  }

  &__bkg-2 {
    position: relative;
    background: linear-gradient(180deg, white 185px, $srg-blue 185px);
    padding-bottom: 32px;
    margin-bottom: 32px;
    .link {
      margin-right: 32px;
    }
  }

  &__welcome {
    color: white;
    margin-top: 64px;
    margin-bottom: 40px;
    width: fit-content;

    span {
      color: $srg-blue;
    }
  }

  .std-side-margin {
    margin-left: calc((100% - 1140px) / 2);
    margin-right: calc((100% - 1140px) / 2);
  }

  &__supplies {
    display: flex;
    justify-content: space-between;
  }

  &__initiative {
    font-size: 32px;
    color: $srg-blue;
    font-weight: 500;
  }

  &__news {
    font-size: 32px;
    color: $srg-blue;
    font-weight: 500;
  }
}
.notCarousel {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  .link {
    width: 100%;
    margin-right: 0px;
  }
}
</style>
<style lang="scss">
.homeNew {
  .VueCarousel-pagination {
    margin-top: 12px;
  }
  &__bkg-1 {
    .VueCarousel-dot-container {
      margin-top: 6px !important;
    }
    .VueCarousel-pagination {
      height: initial;
      margin-top: 16px;
    }
  }
  &__bkg-2 {
    .VueCarousel-navigation {
      margin-bottom: 21px;
    }
  }
}
</style>
