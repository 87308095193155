<template>
  <div :class="`message-center ${collapsed ? 'collapsed' : ''}`" v-if="isReady">
    <div class="content-msg">
      <div class="messages">
        <div class="type d-flex justify-space-between align-center">
          <div class="d-flex align-center">
            <img
              style="cursor: pointer"
              class="mr-3"
              @click="open"
              src="../../assets/ic_chevron_top_black.png"
            />
            <h3>Messaggi</h3>
          </div>
          <v-menu
            nudge-bottom="80"
            nudge-right="100"
            origin="center center"
            transition="scale-transition"
            rounded="xl"
            :disabled="listAccount.length === 1"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="avatar" v-on="on" v-bind="attrs">
                <img
                  v-if="
                    accountPhoto &&
                    mainClient.clientOwnerCode ===
                      clientSelected.clientOwnerCode
                  "
                  :src="accountPhotoSrc"
                  class="photo"
                  alt="account photo"
                />
                <v-img class="photo" :src="IconProfileNoPhoto" v-else />
                <div class="countMessages" v-if="countMessagesClient > 0">
                  {{ countMessagesClient }}
                </div>
              </div>
            </template>
            <div class="selectAccount">
              <span class="d-flex justify-center align-center title"
                ><strong>Seleziona il profilo</strong></span
              >
              <div
                class="d-flex align-center clientMessage"
                v-for="(client, i) in listAccount"
                :key="i"
                @click="setSelectClient(client.codice)"
              >
                <div class="avatar">
                  <img
                    v-if="
                      accountPhoto &&
                      mainClient.clientOwnerCode === client.codice
                    "
                    :src="accountPhotoSrc"
                    class="photo"
                    alt="account photo"
                  />
                  <v-img class="photo" :src="IconProfileNoPhoto" v-else />
                </div>
                <div class="clientData">
                  <h5 class="capitalize">
                    <strong>{{
                      client.nome && client.cognome
                        ? client.nome + ' ' + client.cognome
                        : client.ragioneSociale
                    }}</strong>
                  </h5>
                  <h6 class="mt-1">Codice cliente: {{ client.codice }}</h6>
                </div>
                <div v-if="client.totale > 0" class="total">
                  <strong>{{ client.totale }}</strong>
                </div>
              </div>
            </div>
          </v-menu>
        </div>
        <template v-if="isEmptyCenterMessage">
          <div class="emptyMessage">
            <img src="../../assets/message-center/email-to-read_black.svg" />
            <div class="text">
              Qui troverai informazioni utili sulle tue utenze, le iniziative e
              i servizi offerti
            </div>
          </div>
        </template>
        <template v-else>
          <v-tabs grow v-model="tab" centered>
            <v-tab key="arrivo">
              <div class="tabMessage">In arrivo</div>
            </v-tab>
            <v-tab key="archiviati">
              <div class="tabMessage">Archiviati</div>
            </v-tab>
          </v-tabs>
          <div>
            <v-tabs-items v-model="tab">
              <v-tab-item key="arrivo">
                <div v-if="isEmptyInArriveFiltered" class="emptyMessage mt-8">
                  <div class="text">
                    Nessun risultato<br />per questa ricerca
                  </div>
                </div>
                <div v-else-if="isEmptyInArrive" class="emptyMessage mt-8">
                  <div class="text">
                    Al momento non ci sono messaggi disponibili
                  </div>
                </div>
                <div v-else>
                  <div
                    v-for="message in listMessagesInArrive"
                    :key="message.idMessaggio"
                  >
                    <div
                      class="message"
                      :class="!message.letto ? 'readIt' : ''"
                      v-if="!message.archiviato && !message.cancellato"
                    >
                      <div
                        @click="
                          actionMessage(message)
                          toggleExpansion(message.idMessaggio)
                        "
                        v-scroll-to="scrollToSupply(message)"
                      >
                        <div
                          class="title d-flex justify-space-between align-center"
                        >
                          <h6
                            :class="`category ${
                              message.pinned ? 'pinned' : ''
                            }`"
                          >
                            {{ formatCategoryClass(message.categoria) }}
                          </h6>
                          <h6 class="capitalize">
                            {{
                              clientSelected.firstName &&
                              clientSelected.lastName
                                ? clientSelected.firstName +
                                  ' ' +
                                  clientSelected.lastName
                                : clientSelected.businessName
                            }}
                          </h6>
                        </div>
                        <div :class="`descMessage ${message.categoria}`">
                          <h5 class="mr-3 bold">{{ message.titolo }}</h5>
                          <div class="messageMenu">
                            <v-menu
                              bottom
                              nudge-bottom="30"
                              nudge-left="200"
                              origin="center center"
                              transition="scale-transition"
                              rounded="xl"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <span v-on="on" v-bind="attrs">...</span>
                              </template>
                              <div class="messageActions">
                                <div
                                  v-if="!message.archiviato && !message.pinned"
                                  class="d-flex align-center mb-2"
                                >
                                  <img
                                    class="mr-3"
                                    src="../../assets/message-center/archivia.svg"
                                  />
                                  <h5 @click="setMessageArchived(message)">
                                    Archivia il messaggio
                                  </h5>
                                </div>
                                <div
                                  v-if="!message.pinned"
                                  class="d-flex align-center mb-2"
                                >
                                  <img
                                    class="mr-3"
                                    src="../../assets/message-center/pin.svg"
                                  />
                                  <h5 @click="setMessagePinned(message)">
                                    Fissa in alto il messaggio
                                  </h5>
                                </div>
                                <div v-else class="d-flex align-center mb-2">
                                  <img
                                    class="mr-3"
                                    src="../../assets/message-center/pin.svg"
                                  />
                                  <h5 @click="setMessagePinned(message)">
                                    Non fissare più in alto
                                  </h5>
                                </div>
                                <div
                                  v-if="!message.letto"
                                  class="d-flex align-center"
                                >
                                  <img
                                    class="mr-3"
                                    src="../../assets/message-center/email-to-read.svg"
                                  />
                                  <h5 @click="setMessageRead(message)">
                                    Segna come letto
                                  </h5>
                                </div>
                                <div v-else class="d-flex align-center">
                                  <img
                                    class="mr-3"
                                    src="../../assets/message-center/email-to-read.svg"
                                  />
                                  <h5 @click="setMessageRead(message)">
                                    Segna come da leggere
                                  </h5>
                                </div>
                              </div>
                            </v-menu>
                          </div>
                        </div>
                        <div
                          :id="message.idMessaggio"
                          :class="`descFullMessage ${
                            message.expanded ? 'open' : 'close'
                          } ${message.isCollapsible ? '' : 'not-collapsible'}`"
                        >
                          <h6 class="mr-3 bold">
                            {{ message.testoMessaggio }}
                            <div
                              v-if="showLink(message)"
                              :class="`mLink ${
                                message.tipoLink === 'CHANGE_PRODUCT'
                                  ? 'd-inline'
                                  : ''
                              }`"
                            >
                              {{ message.testoLink || 'Scopri Ora' }}
                            </div>
                            <SpeedTest
                              class="d-block"
                              v-else-if="
                                APP_SECTION_MAPPING[message.link] ===
                                'speedTest'
                              "
                            >
                              <div class="mLink">{{ message.testoLink }}</div>
                            </SpeedTest>
                            <div v-else class="mLink">
                              {{ message.testoLink }}
                            </div>
                          </h6>
                        </div>
                      </div>
                      <div class="deadline ml-9 mt-1">
                        <h6>
                          {{
                            `${
                              message.dataValidita.label
                                ? 'Scade il ' + message.dataValidita.label
                                : ''
                            }`
                          }}
                        </h6>
                        <img
                          v-if="message.isCollapsible"
                          @click="toggleExpansion(message.idMessaggio)"
                          src="../../assets/message-center/ic-chevron-down-black.svg"
                          :class="`expansion ${
                            message.expanded ? 'open' : 'close'
                          }`"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item key="archiviati">
                <div v-if="isEmptyArchiveFiltered" class="emptyMessage mt-8">
                  <div class="text">
                    Nessun risultato<br />per questa ricerca
                  </div>
                </div>
                <div v-else-if="isEmptyArchive" class="emptyMessage mt-8">
                  <div class="text">
                    Al momento non ci sono messaggi disponibili
                  </div>
                </div>
                <div v-else>
                  <div
                    v-for="message in listMessagesArchived"
                    :key="message.idMessaggio"
                  >
                    <div
                      class="message"
                      :class="!message.letto ? 'readIt' : ''"
                      v-if="message.archiviato && !message.cancellato"
                      ref="archivio"
                    >
                      <div
                        @click="
                          actionMessage(message)
                          toggleExpansion(message.idMessaggio)
                        "
                        v-scroll-to="scrollToSupply(message)"
                      >
                        <div
                          class="title d-flex justify-space-between align-center"
                        >
                          <h6
                            :class="`category ${
                              message.pinned ? 'pinned' : ''
                            }`"
                          >
                            {{ formatCategoryClass(message.categoria) }}
                          </h6>
                          <h6>
                            {{
                              clientSelected.firstName &&
                              clientSelected.lastName
                                ? clientSelected.firstName +
                                  ' ' +
                                  clientSelected.lastName
                                : clientSelected.businessName
                            }}
                          </h6>
                        </div>
                        <div :class="`descMessage ${message.categoria}`">
                          <h5 class="mr-3 bold">{{ message.titolo }}</h5>
                          <div class="messageMenu">
                            <v-menu
                              v-if="!isExpired(message)"
                              bottom
                              nudge-bottom="30"
                              nudge-left="200"
                              origin="center center"
                              transition="scale-transition"
                              rounded="xl"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <span v-on="on" v-bind="attrs">...</span>
                              </template>
                              <div class="messageActions">
                                <div class="d-flex align-center mb-2">
                                  <img
                                    class="mr-3"
                                    src="../../assets/message-center/archivia.svg"
                                  />
                                  <h5 @click="setMessageArchived(message)">
                                    Sposta in Arrivo
                                  </h5>
                                </div>
                              </div>
                            </v-menu>
                          </div>
                        </div>
                        <div
                          :id="message.idMessaggio"
                          :class="`descFullMessage ${
                            message.expanded ? 'open' : 'close'
                          } ${message.isCollapsible ? '' : 'not-collapsible'}`"
                        >
                          <h6 class="mr-3 bold">
                            {{ message.testoMessaggio }}
                            <div
                              v-if="showLink(message)"
                              :class="`mLink ${
                                message.tipoLink === 'CHANGE_PRODUCT'
                                  ? 'd-inline'
                                  : ''
                              }`"
                            >
                              {{ message.testoLink || 'Scopri Ora' }}
                            </div>
                            <SpeedTest
                              class="d-block"
                              v-else-if="
                                APP_SECTION_MAPPING[message.link] ===
                                'speedTest'
                              "
                            >
                              <div class="mLink">{{ message.testoLink }}</div>
                            </SpeedTest>
                            <div v-else class="mLink">
                              {{ message.testoLink }}
                            </div>
                          </h6>
                        </div>
                      </div>
                      <div class="deadline ml-9 mt-1">
                        <h6>
                          {{
                            `${
                              message.dataValidita.label
                                ? 'Scade il ' + message.dataValidita.label
                                : ''
                            }`
                          }}
                        </h6>
                        <img
                          v-if="message.isCollapsible"
                          @click="toggleExpansion(message.idMessaggio)"
                          src="../../assets/message-center/ic-chevron-down-black.svg"
                          :class="`expansion ${
                            message.expanded ? 'open' : 'close'
                          }`"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </div>
          <div class="btn-filter">
            <v-btn
              v-if="
                categoryFiltered.length > 0 || stateMessageFiltered.length > 0
              "
              text
              color="primary"
              x-large
              width="234"
              @click="cancelFilter"
              >Azzera Filtri</v-btn
            >

            <v-btn
              color="primary"
              x-large
              :width="
                categoryFiltered.length > 0 ||
                this.stateMessageFiltered.length > 0
                  ? '234'
                  : '350'
              "
              class="v-btn--shadowed"
              @click="openFiter"
              >{{ btnLabelFilter }}
              <img class="ml-1" src="../../assets/message-center/filter-by.svg"
            /></v-btn>
          </div>
        </template>
      </div>
      <div :class="`filter py-10 px-6 ${collapsedFilter ? 'collapsed' : ''}`">
        <div class="title d-flex justify-space-between align-center">
          <h3><strong>Seleziona filtri</strong></h3>
          <img
            @click="openFiter"
            src="../../assets/message-center/arrows-left.svg"
          />
        </div>
        <div class="categories">
          <div class="title">Categoria del messaggio</div>
          <div class="content-category">
            <div
              :class="`category ${
                categorySelected.includes(category) ? 'selected' : ''
              }`"
              v-for="(category, i) in listCategory"
              :key="i"
              @click="setCategorySelected(category)"
            >
              {{ formatCategoryClass(category) }}
            </div>
          </div>
          <div class="title">Stato del messaggio</div>
          <div class="content-category mt-6">
            <div
              :class="`category ${
                stateMessageSelected.includes(state) ? 'selected' : ''
              }`"
              v-for="(state, i) in stateMessage"
              :key="i"
              @click="setStateMessageSelected(state)"
            >
              {{ state }}
            </div>
          </div>
          <hr />
        </div>
        <div class="d-flex justify-space-around align-center">
          <v-btn
            text
            color="primary"
            x-large
            width="234"
            @click="resetFilterSelected"
            >Annulla</v-btn
          >
          <v-btn
            color="primary"
            x-large
            width="234"
            class="v-btn--shadowed"
            @click="applyFilter"
            >Applica filtri</v-btn
          >
        </div>
      </div>
    </div>
    <CfpDialog
      :showFootprint="showFootprint"
      :cfpUrl="cfpUrl"
      :resetPropsCfp="resetPropsCfp"
    />
  </div>
</template>
<script>
import CfpDialog from '@/components/cfp/CfpDialog'
import { ENV_CONFIGS } from '@/js/configs.js'
import {
  APP_SECTION_MAPPING,
  SUPPLY_STATUS,
  MESSAGE_CATEGORY
} from '@/js/constants'
import { eventBus } from '@/main'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import SpeedTest from '@/components/speedTest/'

import IconProfileNoPhoto from '@assets/icons/avatar.svg'
import { accessGreeners } from '../../js/greeners/accessGreeners'
import { ServiceSectionHelper } from '../../js/service/service_section_card_helper'

import _ from 'lodash'

export default {
  name: 'messageCenter',
  props: {
    stateMessage: {
      type: Array,
      default: () => ['letti', 'non letti', 'fissati']
    }
  },
  data() {
    return {
      IconProfileNoPhoto,
      collapsed: true,
      collapsedFilter: true,
      tab: 0,
      clientsForAccount: [],
      clientSelected: null,
      categorySelected: [],
      categoryFiltered: [],
      stateMessageSelected: [],
      stateMessageFiltered: [],
      isReady: false,
      listMessages: [],
      expansion: false,
      APP_SECTION_MAPPING: APP_SECTION_MAPPING,
      SUPPLY_STATUS: SUPPLY_STATUS,
      MESSAGE_CATEGORY: MESSAGE_CATEGORY,
      showFootprint: false,
      cdn: ENV_CONFIGS.CMS_CDN_BASE_URL,
      cfpUrl: ''
    }
  },
  // commented for now to avoid multiple calls, called only in Menu.vue and inside the welcomekit
  /*  watch: {
    'client': function (newValue, oldValue) {
      if (newValue && !oldValue) {
        this.$store.dispatch('welcomekit/getWelcomekitByUserProfile', { client: newValue })
      }
    }
  }, */
  watch: {
    messageCenter() {
      this.initListMessages()
    }
  },
  computed: {
    ...mapGetters('account', [
      'mainClientLoyaltySelected',
      'mainClientLoyaltyRes',
      'mainClientLoyaltyBus',
      'oldestLoyaltyRes',
      'oldestLoyaltyBus',
      'getMessageCenter'
    ]),
    ...mapGetters('account', [
      'mainClient',
      'accountPhoto',
      'numberMessage',
      'isMultipleMainClients',
      'mainClient',
      'accountProperties'
    ]),
    ...mapGetters('supply', ['supplies']),
    ...mapGetters('loyalty', ['memberStatus', 'NEW_MEMBER_STATUS_ERROR']),
    ...mapGetters('session', ['token']),
    ...mapGetters('feedback', ['client']),
    ...mapGetters('welcomekit', ['welcomekit']),
    accountPhotoSrc() {
      return URL.createObjectURL(this.accountPhoto)
    },
    messageCenter() {
      return this.getMessageCenter.listaClienti
    },
    isEmptyCenterMessage() {
      let isEmpty = true
      this.messageCenter.forEach((m) => {
        if (m.listaMessaggi.length > 0) {
          isEmpty = false
        }
      })
      return isEmpty
    },
    listAccount() {
      return this.messageCenter.map((m) => {
        return {
          nome: this.clientsForAccount[m.codiceCliente].firstName,
          cognome: this.clientsForAccount[m.codiceCliente].lastName,
          ragioneSociale: this.clientsForAccount[m.codiceCliente].businessName,
          codice: m.codiceCliente,
          totale: m.listaMessaggi.filter(
            (msg) => msg.letto === false && msg.cancellato === false
          ).length
        }
      })
    },
    listMessagesInArrive() {
      return this.listMessages.filter((m) => !m.archiviato && !m.cancellato)
    },
    isEmptyInArrive() {
      return this.listMessagesInArrive.length === 0
    },
    listMessagesArchived() {
      return this.listMessages.filter((m) => m.archiviato && !m.cancellato)
    },
    isEmptyArchive() {
      return this.listMessagesArchived.length === 0
    },
    isEmptyInArriveFiltered() {
      return (
        this.isEmptyInArrive &&
        (this.categoryFiltered.length > 0 ||
          this.stateMessageFiltered.length > 0)
      )
    },
    isEmptyArchiveFiltered() {
      return (
        this.isEmptyArchive &&
        (this.categoryFiltered.length > 0 ||
          this.stateMessageFiltered.length > 0)
      )
    },
    countMessagesClient() {
      let count = this.listMessages.filter(
        (m) => !m.letto && !m.cancellato
      ).length
      return count && count > 0 ? count : 0
    },
    listCategory() {
      // let list = this.messageCenter.find(m => m.codiceCliente === this.clientSelected.clientOwnerCode).listaCategorie[0]
      // return Object.keys(list).filter(c => parseInt(list[c]) > 0).map(category => this.uniformCategory(category))
      let list = this.messageCenter.find(
        (m) => m.codiceCliente === this.clientSelected.clientOwnerCode
      ).listaMessaggi
      let listFiltered = list.filter((l) => l.cancellato === false)
      let listCat = []
      listFiltered.forEach((l) => {
        let cat = this.formatCategoryFilter(l.categoria)
        if (!listCat.includes(cat)) {
          listCat.push(cat)
        }
      })
      return listCat
    },
    btnLabelFilter() {
      if (
        this.categoryFiltered.length > 0 ||
        this.stateMessageFiltered.length > 0
      ) {
        let count = this.categoryFiltered.concat(
          this.stateMessageFiltered
        ).length
        return 'Filtri (' + count + ')'
      } else {
        return 'Filtra per'
      }
    }
  },
  async beforeMount() {
    // commented to avoid multiple calls, called only in account.js
    // this.getAccountPhoto()
    eventBus.$on('message-center:open', this.open)
  },
  // updated () {
  //   console.log('update')
  //   this.$nextTick(function () {
  //     this.setMessageHeight()
  //   })
  // },
  mounted() {
    let _this = this
    let firstEnter = false
    document.addEventListener(
      'mousemove',
      _.throttle(mouseMoveEventAction, 200)
    )

    function mouseMoveEventAction(e) {
      if (_this.isReady) {
        doPanelStuff(isInsideThreshold(e.clientX))
      }
    }

    function doPanelStuff(isActive) {
      if (document.querySelector('.message-center')) {
        if (
          !document
            .querySelector('.message-center')
            .classList.contains('collapsed') &&
          isActive
        ) {
          firstEnter = true
        }
        if (
          !isActive &&
          !document
            .querySelector('.message-center')
            .classList.contains('collapsed') &&
          firstEnter
        ) {
          _this.collapsed = true
          firstEnter = false
        }
        _this.setMessageHeight()
      }
    }
    function isInsideThreshold(cursorX) {
      var threshold = 490
      var clientWidth = document.documentElement.clientWidth
      return cursorX > clientWidth - threshold
    }
  },
  created() {
    let messageCenterStorage = JSON.parse(localStorage.getItem('messageCenter'))
    let hasMessageStorage = []

    this.getClientsForAccount().then(async (resp) => {
      this.clientsForAccount = resp

      if (messageCenterStorage) {
        //se sono passati 10 minuti dall'ultimo messaggio ricevuto isValideMessageStorage ritorna un array vuoto
        hasMessageStorage = this.isValideMessageStorage(messageCenterStorage)
      }
      // se isValideMessageStorage ritorna un array vuoto allora qua non entra e ripete la chiamate nell'else
      if (messageCenterStorage && hasMessageStorage.length > 0) {
        // this.messageCenter = messageCenterStorage //prima era così
        this.setMessageCenter({ listaClienti: messageCenterStorage })
        this.refreshNumberMessage()
        this.isReady = true
        this.clientSelected =
          this.clientsForAccount[this.mainClient.clientOwnerCode]
        this.initListMessages()
      } else {
        this.getNumberMessage(Object.keys(resp))
        await this.retrieveMessageCenter(Object.keys(resp))
        this.isReady = true
        this.clientSelected =
          this.clientsForAccount[this.mainClient.clientOwnerCode]
        this.initListMessages()
      }
    })
  },
  methods: {
    formatCategoryClass(val) {
      switch (val) {
        case 'greeners':
          return 'Greeners'
        case 'myshop':
          return 'MyShop'
        case 'iniziative':
          return 'Iniziative'
        case 'info':
          return 'Informative'
        case 'utenzafibra':
          return 'Utenza fibra'
        case 'utenzaluce':
          return 'Utenza luce'
        case 'utenzagas':
          return 'Utenza gas'
        case 'vantaggiperte':
          return 'Vantaggi per te'
      }
    },
    ...mapActions('account', [
      'getAccountPhoto',
      'getClientsForAccount',
      'retrieveMessageCenter',
      'getNumberMessage',
      'getStatusMessage',
      'isZeroMainClients'
    ]),
    ...mapMutations('account', ['setNumberMessage', 'setMessageCenter']),
    ...mapMutations('offers', ['setParamsChangeProduct']),
    ...mapMutations('upsellingComm', ['setIsDeepLink']),
    ...mapActions('analytics', ['trackTapEvent']),
    formatCategoryFilter(val) {
      switch (val) {
        case 'Greeners':
          return 'greeners'
        case 'MyShop':
          return 'myshop'
        case 'Iniziative':
          return 'iniziative'
        case 'Info':
          return 'info'
        case 'Utenza fibra':
          return 'utenzafibra'
        case 'Utenza luce':
          return 'utenzaluce'
        case 'Utenza gas':
          return 'utenzagas'
        case 'Vantaggi per te':
          return 'vantaggiperte'
      }
    },
    setMessageHeight() {
      this.listMessages.map((m) => {
        if (document.getElementById(m.idMessaggio)) {
          if (document.getElementById(m.idMessaggio).scrollHeight > 0) {
            m.isCollapsible =
              document.getElementById(m.idMessaggio).scrollHeight > 88
          }
          return m
        }
      })
      this.$forceUpdate()
    },
    open: function () {
      this.collapsed = !this.collapsed
      if (this.collapsed) {
        this.collapsedFilter = true
      }
    },
    openFiter: function () {
      this.collapsedFilter = !this.collapsedFilter
    },
    resetFilterSelected() {
      this.categorySelected = this.categoryFiltered
      this.stateMessageSelected = this.stateMessageFiltered
      this.collapsedFilter = true
    },
    cancelFilter() {
      this.categorySelected = []
      this.categoryFiltered = []
      this.stateMessageSelected = []
      this.stateMessageFiltered = []
      this.initListMessages()
    },
    applyFilter() {
      let _this = this
      this.initListMessages()

      if (this.categorySelected.length > 0) {
        this.listMessages = this.listMessages.filter((m) => {
          return _this.categorySelected.includes(m.categoria)
        })
      }

      if (this.stateMessageSelected.length > 0) {
        this.listMessages = this.listMessages.filter((m) => {
          let hasCategory = false
          _this.stateMessageSelected.forEach((s) => {
            if (s === 'letti' && m.letto) hasCategory = true
            if (s === 'non letti' && !m.letto) hasCategory = true
            if (s === 'fissati' && m.pinned) hasCategory = true
          })
          return hasCategory
        })
      }
      this.categoryFiltered = this.categorySelected
      this.stateMessageFiltered = this.stateMessageSelected
      this.collapsedFilter = true
    },
    uniformCategory(category) {
      return category.replace(/\s+/g, '').toLowerCase()
    },
    initListMessages() {
      let messagesObj = this.messageCenter.filter((m) => {
        if (m.codiceCliente === this.clientSelected?.clientOwnerCode) {
          return m
        }
      })
      if (messagesObj.length > 0) {
        this.listMessages = messagesObj[0].listaMessaggi.map((mes) => {
          let isExpired = false
          let date = this.setDate(mes.dataValidita)
          if (!isNaN(new Date(date.date).getTime())) {
            isExpired = new Date(date.date) < new Date()
          }
          return {
            idMessaggio: mes.idMessaggio,
            codiceCliente: mes.codiceCliente,
            titolo: mes.titolo,
            testoMessaggio: mes.testoMessaggio,
            dataInvio: this.setDate(mes.dataInvio),
            dataValidita: date,
            categoria: this.uniformCategory(mes.categoria),
            letto: mes.letto === true,
            archiviato: mes.archiviato === true || isExpired,
            pinned: mes.pinned === true,
            cancellato: mes.cancellato === true,
            expanded: false,
            link: mes.link,
            testoLink: mes.testoLink,
            tipoLink: mes.tipoLink,
            isLink:
              (mes.link !== null &&
                mes.testoLink !== null &&
                mes.tipoLink !== null) ||
              mes.tipoLink === 'CHANGE_PRODUCT',
            scrollActiveted: false,
            productCode: mes.productCode || undefined,
            tariffList: mes.tariffList || undefined
          }
        })
      }
      this.orderListMessage()
    },
    /* la isValideMessageStorage calcola la differenza di tempo tra la data corrente e la data dell'ultimo messaggio (salvata su localstorage)
    se è inferiore o uguale a 10 minuti, la funzione restituirà un array contenente i messaggi fitlrati
    altrimenti la funzione restituirà un array vuoto, in modo tale che venga rifatta la chiamata a retrieveMessageCenter
    */
    isValideMessageStorage(messageCenterStorage) {
      let date = new Date(localStorage.getItem('messageCenterDate'))
      if ((new Date() - date) / 1000 / 60 <= ENV_CONFIGS.MSG_CENTER_DELAY) {
        return messageCenterStorage.filter(
          (m) => m.codiceCliente === this.mainClient.clientOwnerCode
        )
      } else {
        return []
      }
    },
    setDate(val) {
      if (!val) return { date: '', label: '' }
      if (typeof val === 'object') {
        val = val.label
      }
      let date = new Date(val.split('/').reverse().join('/'))
      let formatDate = `${
        date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      }/${
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      }/${date.getFullYear()}`
      return { date: date, label: formatDate }
    },
    orderListMessage() {
      this.listMessages = this.listMessages.sort((a, b) => {
        if (a.pinned && b.pinned) {
          if (a.dataInvio.date > b.dataInvio.date) {
            return -1
          }
          if (a.dataInvio.date < b.dataInvio.date) {
            return 1
          }
        }
        if (a.pinned && !b.pinned) {
          return -1
        }
        if (!a.pinned && b.pinned) {
          return 1
        }
        if (!a.pinned && !b.pinned) {
          if (a.dataInvio.date > b.dataInvio.date) {
            return -1
          }
          if (a.dataInvio.date < b.dataInvio.date) {
            return 1
          }
        }
        return 0
      })
      this.$forceUpdate()
    },
    setMessageArchived(message) {
      this.listMessages = this.listMessages.map((m) => {
        if (m.idMessaggio === message.idMessaggio) {
          m.archiviato = !m.archiviato
        }
        return m
      })
      this.orderListMessage()
      this.$forceUpdate()
      this.storeMessage(message)
    },
    setMessagePinned(message) {
      this.listMessages = this.listMessages.map((m) => {
        if (m.idMessaggio === message.idMessaggio) {
          m.pinned = !m.pinned
        }
        return m
      })
      this.orderListMessage()
      this.$forceUpdate()
      this.storeMessage(message)
    },
    setMessageRead(message) {
      this.listMessages = this.listMessages.map((m) => {
        if (m.idMessaggio === message.idMessaggio) {
          m.letto = !m.letto
        }
        return m
      })
      this.$forceUpdate()
      this.storeMessage(message)
    },
    setSelectClient(codice) {
      this.clientSelected = this.clientsForAccount[codice]
      this.initListMessages()
      this.applyFilter()
    },
    storeMessage(message) {
      let storeData = {
        idMessaggio: message.idMessaggio,
        codiceCliente: message.codiceCliente,
        titolo: message.titolo,
        testoMessaggio: message.testoMessaggio,
        dataInvio: message.dataInvio.date.toLocaleDateString(),
        dataValidita: message.dataValidita,
        categoria: message.categoria,
        letto: !!message.letto,
        archiviato: !!message.archiviato,
        pinned: !!message.pinned,
        cancellato: !!message.cancellato,
        link: message.link,
        testoLink: message.testoLink,
        tipoLink: message.tipoLink,
        productCode: message.productCode || undefined,
        tariffList: message.tariffList || undefined
      }
      this.getStatusMessage(storeData)

      let newLista = this.messageCenter
        .find((m) => m.codiceCliente === message.codiceCliente)
        .listaMessaggi.map((m) => {
          if (m.idMessaggio === storeData.idMessaggio) {
            return {
              idMessaggio: storeData.idMessaggio,
              codiceCliente: storeData.codiceCliente,
              titolo: m.titolo,
              testoMessaggio: m.testoMessaggio,
              dataInvio: m.dataInvio,
              dataValidita: m.dataValidita,
              categoria: m.categoria,
              letto: storeData.letto,
              archiviato: storeData.archiviato,
              pinned: storeData.pinned,
              cancellato: storeData.cancellato,
              link: m.link,
              testoLink: m.testoLink,
              tipoLink: m.tipoLink,
              productCode: m.productCode || undefined,
              tariffList: m.tariffList || undefined
            }
          }
          return m
        })
      this.messageCenter.find(
        (m) => m.codiceCliente === message.codiceCliente
      ).listaMessaggi = newLista

      this.refreshNumberMessage()

      localStorage.setItem('messageCenter', JSON.stringify(this.messageCenter))
      localStorage.setItem('messageCenterDate', new Date().toISOString())
    },

    refreshNumberMessage() {
      let refreshNumberMessage = this.messageCenter.map((m) => {
        return {
          codiceCliente: m.codiceCliente,
          numeroMessaggiDaLeggere: m.listaMessaggi.filter(
            (mes) => mes.letto === false && mes.cancellato === false
          ).length
        }
      })

      this.setNumberMessage({ listaClienti: refreshNumberMessage })
    },
    setCategorySelected(category) {
      if (this.categorySelected.includes(category)) {
        this.categorySelected = this.categorySelected.filter(
          (c) => c !== category
        )
      } else {
        this.categorySelected.push(category)
      }
    },
    setStateMessageSelected(state) {
      if (this.stateMessageSelected.includes(state)) {
        this.stateMessageSelected = this.stateMessageSelected.filter(
          (c) => c !== state
        )
      } else {
        this.stateMessageSelected.push(state)
      }
    },
    setActionRead(message) {
      this.listMessages = this.listMessages.map((m) => {
        if (m.idMessaggio === message.idMessaggio) {
          m.letto = true
          m.scrollActiveted = m.expanded || !m.isCollapsible
        }
        return m
      })
      this.$forceUpdate()
      this.storeMessage(message)
    },
    actionMessage(message) {
      this.trackTapEvent({
        name: 'msgcenter_msg_tap',
        params: {
          msg_title: message.titolo,
          category: MESSAGE_CATEGORY[message.categoria]
        }
      })

      if (message.link === 'upsellingLuce') {
        this.trackTapEvent({
          name: 'subscription_started',
          params: {
            services_to_subscribe: 'ele',
            entry_point: 'message_center'
          }
        })
        this.setIsDeepLink(false)
      }
      if (message.link === 'upsellingGas') {
        this.trackTapEvent({
          name: 'subscription_started',
          params: {
            services_to_subscribe: 'gas',
            entry_point: 'message_center'
          }
        })
        this.setIsDeepLink(false)
      }
      if (message.isLink) {
        if (message.isCollapsible) {
          if (message.expanded) {
            this.setActionRead(message)
            this.goToLink(message)
            this.collapsed = true
          }
        } else {
          this.setActionRead(message)
          this.goToLink(message)
          this.collapsed = true
        }
      } else {
        if (message.isCollapsible) {
          if (message.expanded) {
            this.setActionRead(message)
            this.goToAction(message)
            this.collapsed = true
          }
        } else {
          this.setActionRead(message)
          this.goToAction(message)
          this.collapsed = true
        }
      }
    },
    async goToLoyalty() {
      let isGreener = false
      if (this.mainClient) {
        if (!this.isMultipleMainClients) {
          isGreener =
            this.accountProperties.hasGasActivating ||
            this.accountProperties.hasGasActive ||
            this.accountProperties.hasLightActivating ||
            this.accountProperties.hasLightActive ||
            this.accountProperties.hasFiberActivating ||
            this.accountProperties.hasFiberActive
        } else {
          isGreener =
            this.supplies.filter(
              (sup) =>
                (sup.supplyState === this.SUPPLY_STATUS.ACTIVE ||
                  sup.supplyState === this.SUPPLY_STATUS.ACTIVATING) &&
                sup.clientOwnerType === 'RES'
            ).length > 0
        }
      }
      if (isGreener) {
        let resp = await accessGreeners()
        if (resp === 'multiClients') {
          eventBus.$emit('greenersSelectClient', 'prova')
          return false
        }
        if (this.NEW_MEMBER_STATUS_ERROR) {
          this.$router.push({
            name: 'home',
            params: { typeError: 'IDENTITY_STATUS_ERROR' }
          })
        }
        this.$router.push({ name: 'loyaltyPage' })
      }
      return false
    },
    goToMyShop(message) {
      this.$router.push({
        name: 'myshop',
        params: { clientCode: message.codiceCliente, token: this.token }
      })
    },
    goToHome() {
      this.$router.push({ name: 'home' })
    },
    goToVantaggi() {
      if (this.welcomekit) {
        this.$router.push({ name: 'welcomekit' })
      } else {
        return false
      }
    },
    goToAction(message) {
      if (message.categoria === 'greeners') {
        this.goToLoyalty()
      }
      if (message.categoria === 'myshop') {
        this.goToMyShop(message)
      }
      if (
        message.categoria === 'utenzafibra' ||
        message.categoria === 'utenzaluce' ||
        message.categoria === 'utenzagas'
      ) {
        this.goToHome()
      }
      // if (message.categoria === 'vantaggiperte') {
      //   this.goToVantaggi()
      // }
    },
    goToLink(message) {
      this.collapsed = true
      if (message.tipoLink === 'WK_SECTION') {
        if (this.welcomekit) {
          this.$router.push({ path: '/private' + message.link })
        }
      } else if (message.tipoLink === 'APP_SECTION') {
        if (this.APP_SECTION_MAPPING[message.link] === 'speedTest') {
          eventBus.$emit('speed-test:open')
        } else if (
          this.APP_SECTION_MAPPING[message.link] === 'carbonFootprint'
        ) {
          this.openFootprint()
        } else if (this.APP_SECTION_MAPPING[message.link] !== undefined) {
          this.$router.push({ name: this.APP_SECTION_MAPPING[message.link] })
        } else {
          if (
            message.link.includes('profile_survey') &&
            this.accountProperties.detailedType.includes('RES')
          ) {
            this.$router.push(ServiceSectionHelper.getSectionUrl(message.link))
          }
        }
      } else if (message.tipoLink === 'CHANGE_PRODUCT') {
        this.setParamsChangeProduct({
          clientCode: message.codiceCliente,
          prCode: message.link,
          tariffList: message.tariffList || undefined,
          productCode: message.productCode || undefined
        })
        this.$router.push({
          name: 'changeOfferCommodity',
          params: { type: message.categoria }
        })
      } else {
        window.open(message.link, '_blank').focus()
      }
    },
    openFootprint() {
      this.cfpUrl = `${ENV_CONFIGS.CFP_BASE_URL}/?clientCode=${
        this.clientSelected.clientOwnerCode
      }&clientType=${this.clientSelected.clientOwnerType}&name=${
        this.clientSelected.firstName
      }&surname=${this.clientSelected.lastName}&hasLightContracts=${
        this.accountProperties.hasLightActivating ||
        this.accountProperties.hasLightActive
      }&token=${this.token}&accountType=${
        this.accountProperties.type
      }&isWeb=true`
      if (this.isMultipleMainClients && !this.isZeroMainClients) {
        // Workaround to show the bonifica-alert also when openinig CFP. (redirect to loyalty which makes same checks since we have no route for CFP, which is open inside dialog in home)
        this.$router.push({ name: 'loyaltyPage' })
      } else {
        this.showFootprint = true
      }
    },
    resetPropsCfp() {
      this.showFootprint = false
      this.cfpUrl = ''
    },
    showLink(message) {
      if (message.isLink) {
        if (
          message.tipoLink === 'APP_SECTION' &&
          (this.APP_SECTION_MAPPING[message.link] === 'speedTest' ||
            APP_SECTION_MAPPING[message.link] === 'carbonFootprint')
        ) {
          return false
        } else if (message.tipoLink === 'CHANGE_PRODUCT') {
          return true
        } else if (message.tipoLink === 'WK_SECTION') {
          return this.welcomekit !== null
        } else {
          return false
        }
      }
    },
    scrollToSupply(message) {
      if (message.isLink) return false
      if (
        message.categoria === 'utenzafibra' ||
        message.categoria === 'utenzaluce' ||
        message.categoria === 'utenzagas'
      ) {
        return message.scrollActiveted ? '#scrollTo-supplies' : false
      } else {
        return ''
      }
    },
    isExpired(message) {
      let isExpired = false
      let date = this.setDate(message.dataValidita)
      if (!isNaN(new Date(date.date).getTime())) {
        isExpired = new Date(date.date) < new Date()
      }
      return isExpired
    },
    toggleExpansion(id) {
      let storeMessage = null
      this.listMessages = this.listMessages.map((m) => {
        if (m.idMessaggio === id) {
          m.expanded = !m.expanded
          m.letto = true
          m.scrollActiveted = m.expanded || !m.isCollapsible
          storeMessage = m
          let heightLink = 0
          if (document.getElementById(id).scrollHeight > 88) {
            heightLink = 30
          }
          document.getElementById(id).style.height = !m.expanded
            ? '88px'
            : document.getElementById(id).scrollHeight + heightLink + 'px'
        }
        return m
      })
      this.$forceUpdate()
      this.storeMessage(storeMessage)
    }
  },
  components: {
    SpeedTest,
    CfpDialog
  }
}
</script>
<style lang="scss" scoped>
.emptyMessage {
  width: 468px;
  padding: 30px 82px;
  border-radius: 20px;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  img {
    width: 48px;
    margin-bottom: 10px;
  }
  .text {
    font-size: 20px;
    font-weight: bold;
    color: black;
    text-align: center;
  }
}
.v-window-item {
  cursor: pointer;
}
.capitalize {
  text-transform: capitalize;
}
.v-item-group {
  height: calc(100vh - 320px);
  overflow-y: scroll;
  margin-bottom: 50px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.35) transparent;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
.v-menu__content {
  border-radius: 20px;
}
.messageActions {
  width: 271px;
  padding: 25px 15px;
  background: white;
  h5 {
    font-size: 14px !important;
    color: black;
    cursor: pointer;
  }
}
.selectAccount {
  width: 400px;
  padding: 20px 25px 25px;
  background: white;
  overflow-y: scroll;
  max-height: 100vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.35) transparent;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .title {
    font-size: 20px;
  }
  .clientMessage {
    margin-top: 19px;
    padding-bottom: 19px;
    border-bottom: 1px solid #dfe4ef;
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }
    .avatar {
      width: 45px;
      height: 45px;
      // box-shadow: 0 5px 20px 4px rgba(0, 0, 0, 0.12), 0 0 10px 0 rgba(0, 0, 0, 0.23);
      border-radius: 23px;
      .photo {
        width: 45px;
        height: 45px;
      }
    }
    .clientData {
      margin-left: 20px;
      align-self: flex-end;
    }
    .total {
      flex: 1;
      text-align: right;
      align-self: flex-end;
      font-size: 14px;
    }
  }
}
.message-center {
  width: 520px;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  background: white;
  box-shadow: -10px 0 10px 0 rgb(0 0 0 / 12%);
  transition: transform 0.5s ease;
  z-index: 999;
  &.collapsed {
    transform: translateX(100%);
  }
  .content-msg {
    position: relative;
    width: 100%;
    height: 100%;
    .type {
      height: 150px;
      padding: 0px 20px;
      h3 {
        font-weight: bold;
      }
    }
    .btn-filter {
      display: flex;
      align-content: center;
      justify-content: center;
    }
    .messages {
      display: flex;
      flex-direction: column;
      .avatar {
        position: relative;
        .photo {
          width: 40px;
          height: 40px;
          cursor: pointer;
          border-radius: 50%;
        }
        .countMessages {
          position: absolute;
          top: -5px;
          right: -8px;
          background: $srg-purple;
          border-radius: 12px;
          color: white;
          font-size: 12px;
          font-weight: bold;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .message {
        height: auto;
        padding: 5px 15px;
        border-bottom: 1px solid #dfe4ef;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        &.readIt {
          background: #f7faff;
        }
        .title {
          .category {
            text-transform: uppercase;
            font-size: 12px;
            letter-spacing: 1px;
            color: black;
            &.pinned::before {
              content: '';
              width: 10px;
              height: 10px;
              border-radius: 5px;
              background: #12256a;
              margin-right: 15px;
              display: inline-block;
            }
          }
        }
        .descMessage {
          display: flex;
          align-items: top;
          &.info {
            background-color: transparent !important;
            border: none !important;
          }
          .bold {
            font-weight: bold;
          }
          &.greeners::before {
            content: url('../../assets/message-center/greeners-outline.svg');
            margin-right: 12px;
            padding-top: 5px;
          }
          &.myshop::before {
            content: url('../../assets/message-center/shopping-cart.svg');
            margin-right: 12px;
            padding-top: 5px;
          }
          &.info::before {
            content: url('../../assets/message-center/info-blue.svg');
            margin-right: 12px;
            padding-top: 5px;
          }
          &.utenzaluce::before {
            content: url('../../assets/message-center/light-small-blu.svg');
            margin-right: 12px;
            padding-top: 5px;
          }
          &.utenzagas::before {
            content: url('../../assets/message-center/fire-small-blu.svg');
            margin-right: 12px;
            padding-top: 5px;
          }
          &.utenzafibra::before {
            content: url('../../assets/message-center/wifi-small-blu.svg');
            margin-right: 12px;
            padding-top: 5px;
          }
          &.iniziative::before {
            content: url('../../assets/message-center/calendar-green.svg');
            margin-right: 12px;
            padding-top: 5px;
          }
          &.vantaggiperte::before {
            content: url('../../assets/message-center/discount.svg');
            margin-right: 12px;
            padding-top: 5px;
          }
          .messageMenu {
            width: 15%;
            justify-content: flex-end;
            display: flex;
            align-items: flex-start;
            flex: 1;
            span {
              font-size: 36px;
              letter-spacing: 2px;
              font-weight: bold;
              line-height: 8px;
            }
          }
        }
        .descFullMessage {
          padding-left: 36px;
          padding-right: 36px;
          display: block;
          margin-bottom: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          line-clamp: 3;
          -webkit-box-orient: vertical;
          h6 {
            font-size: 14px;
            color: black;
            .mLink {
              display: block;
              margin-top: 5px;
              font-size: 16px;
              color: #12256a;
              font-weight: 500;
            }
          }
          &.open {
            // max-height: 600px;
            text-overflow: initial;
            -webkit-line-clamp: initial;
            line-clamp: initial;
            -webkit-box-orient: initial;
            transition: all 0.1s ease-in;
          }
          &.close {
            height: 88px;
            transition: all 0.1s ease-in;
          }
          &.not-collapsible {
            height: 65px;
          }
        }
        .deadline {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .expansion {
            transition: all 0.1s ease-in;
            width: 20px;
            height: auto;
            &.close {
              transform: rotate(0deg);
            }
            &.open {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    .filter {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: white;
      transition: transform 0.5s ease;
      &.collapsed {
        transform: translateX(100%);
      }
      .categories {
        .title {
          margin-top: 40px;
          font-size: 22px;
          font-weight: 500;
          margin-bottom: 16px;
        }
        .content-category {
          display: flex;
          align-content: center;
          flex-wrap: wrap;
          .category {
            margin: 0 16px 16px 0;
            padding: 9px 20px 9px 16px;
            border-radius: 23px;
            border: solid 1.1px #dbdbdb;
            cursor: pointer;
            color: #b3b3b3;
            font-weight: 500;
            &.selected {
              border: solid 1.1px #cccccc;
              background: #cccccc;
              color: black;
            }
          }
        }
        hr {
          height: 1px;
          margin: 37px 0 32px;
          opacity: 0.12;
          background: #04196b;
        }
      }
    }
  }
  .tabMessage {
    font-size: 20px;
    color: black;
    text-transform: none;
    font-weight: normal;
  }
  .v-tab.v-tab--active {
    .tabMessage {
      color: #12256a;
      font-weight: bold;
    }
  }
}
</style>
